import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SiGooglemessages } from "react-icons/si";
import Loader from "../Loader";
import BASE_URL from "../config";
const UserChatRooms = (userId) => {
    const navigate = useNavigate();
    const [chatHistoryRoomsData, setChatHistoryRoomsData] = useState([]);
    const [totalChatHistoryPages, setTotalChatHistoryPages] = useState(null);
    const [totalChatHistoryCount, setTotalChatHistoryCount] = useState(null);
    const [currentChatHistoryPage, setCurrentChatHistoryPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getChatHistoryDataOfSpecificUser = async () => {
        const controller = new AbortController();
        const signal = controller?.signal;

        setIsLoading(true);
        const chatHistoryApiUrl = `${BASE_URL}/chatRoute/get-chat-rooms-by-user?page=${currentChatHistoryPage}`;
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ userId: userId.userId }),
            signal,
        };
        const response = await fetch(chatHistoryApiUrl, options);
        const data = await response.json();
        if (response.ok === true) {
            const { chatRooms, totalChatRooms, totalPages } = data;
            setChatHistoryRoomsData(chatRooms);
            setTotalChatHistoryPages(totalPages);
            setTotalChatHistoryCount(totalChatRooms);
            setIsLoading(false);
            console.log("specific user chat room list", chatRooms);
        } else {
            // console.log(response.ok)
        }
        return () => controller.abort();
    };

    const getSearchResults = async () => {
        const controller = new AbortController();
        const signal = controller?.signal;

        const searchApiUrl = `${BASE_URL}/chatRoute/search-chat-rooms?search=${searchQuery}`;
        const response = await fetch(searchApiUrl, { signal });

        try {
            const data = await response.json();
            const { chatRooms } = data;
            setChatHistoryRoomsData(chatRooms);
            setIsSearchActive(true);
        } catch (error) {
            if (error.name !== "AbortError") {
                console.error("Error fetching search results:", error);
            }
        }

        return () => controller.abort();
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);
        if (value.trim() === "") {
            setIsSearchActive(false);
            getChatHistoryDataOfSpecificUser();
        } else {
            getSearchResults();
        }
    };

    const handleViewChat = (chat) => {
        const { user, astrologer, updatedAt } = chat;

        navigate(`/dashboard/getRoomChat/${`${user._id}_${astrologer._id}`}`, {
            state: { user, astrologer, updatedAt },
        });
    };

    const handleChatRoomsPage = (direction) => {
        if (direction === "prev" && currentChatHistoryPage > 1) {
            setCurrentChatHistoryPage(currentChatHistoryPage - 1);
        } else if (
            direction === "next" &&
            currentChatHistoryPage < totalChatHistoryPages
        ) {
            setCurrentChatHistoryPage(currentChatHistoryPage + 1);
        }
    };

    useEffect(() => {
        if (!isSearchActive) {
            getChatHistoryDataOfSpecificUser();
        }
    }, [currentChatHistoryPage]);

    const renderChatHistoryTable = () => (
        <div>
            <div className="astro-table-container pymnts-container">
                <table className="astro-table">
                    <thead className="astro-he">
                        <tr className="astro-bg">
                            <th className="he">Astrologer Name</th>
                            <th className="he">Astrologer Phone_number</th>
                            <th className="he">Date/Time</th>
                            <th className="he">View Chat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {chatHistoryRoomsData.map((chat) => (
                            <tr className="astro-rows" key={chat._id}>
                                <td
                                    className="he"
                                    onClick={() => {
                                        navigate(
                                            `/dashboard/astrologer/${chat._id}`,
                                            {
                                                state: {
                                                    astrologer: chat.astrologer,
                                                },
                                            }
                                        );
                                    }}
                                    style={{ color: "blue", cursor: "pointer" }}
                                >
                                    {chat.astrologer?.astrologerName ||
                                        "Loading..."}
                                </td>
                                <td className="he">
                                    {chat?.astrologer?.phone_number || "NA"}
                                </td>

                                <td className="he">{chat?.updatedAt}</td>
                                <td className="he">
                                    {chat?.messageCount > 0 ? (
                                        <SiGooglemessages
                                            className="messeges-google-icon"
                                            onClick={() => handleViewChat(chat)}
                                            size={35}
                                        />
                                    ) : (
                                        "No Chat History Found"
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {!isSearchActive && (
                <div className="pagination">
                    <button
                        className="pagination-button"
                        onClick={() => handleChatRoomsPage("prev")}
                        disabled={currentChatHistoryPage === 1}
                    >
                        Prev
                    </button>
                    <span className="pagination-info">
                        Page {currentChatHistoryPage} of {totalChatHistoryPages}
                    </span>
                    <button
                        className="pagination-button"
                        onClick={() => handleChatRoomsPage("next")}
                        disabled={
                            currentChatHistoryPage === totalChatHistoryPages
                        }
                    >
                        Next
                    </button>
                </div>
            )}
        </div>
    );

    return (
        <div>
            <div>
                <input
                    type="search"
                    placeholder="Search by Username, Astrologer Name, or Phone Number"
                    className="search-bar"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
                <button
                    className="btn"
                    onClick={() => getChatHistoryDataOfSpecificUser()}
                >
                    {isLoading === true ? "Loading..." : "Reload"}
                </button>
            </div>

            {isLoading ? (
                <Loader />
            ) : chatHistoryRoomsData.length > 0 ? (
                renderChatHistoryTable()
            ) : (
                "Currently No Chat History Available on these User !"
            )}
        </div>
    );
};

export default UserChatRooms;
