import { useEffect, useState } from "react";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../config";
import { SiGooglemessages } from "react-icons/si";
const CompletedFailedChats = () => {
    const [completedFailedChatsData, setCompletedFailedChatsData] = useState(
        []
    );
    const [currentCompletedFailedChatPage, setCurrentCompletedFailedChatPage] =
        useState(1);
    const [totalCompletedFailedChatsPages, setTotalCompletedFailedChatsPages] =
        useState(null);
    const [selectedDate, setSelectedDate] = useState("");
    const [totalCompletedFailedChats, setTotalCompletedFailedChats] =
        useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [totalFailedFreeChats, setTotalFailedFreeChats] = useState(0);
    const [totalFailedPaidChats, setTotalFailedPaidChats] = useState(0);
    const [totalFailedAmount, setTotalFailedAmount] = useState(0);
    const [totalTransactionsFailedCount, setTotalTransactionsFailedCount] =
        useState(0);
    const navigate = useNavigate();
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(searchQuery);
        }, 500);
        return () => clearTimeout(handler);
    }, [searchQuery]);

    const getCompletedChats = async () => {
        const controller = new AbortController();
        const signal = controller?.signal;

        try {
            setIsLoading(true);

            let completedChatsApiUrl;

            if (selectedDate) {
                completedChatsApiUrl = `${BASE_URL}/user/get-completedFailedChats-by-date?date=${selectedDate}&page=${currentCompletedFailedChatPage}`;
            } else if (debouncedSearch.trim()) {
                completedChatsApiUrl = `${BASE_URL}/user/search-completed-Failed-chats?search=${debouncedSearch}`;
            } else {
                completedChatsApiUrl = `${BASE_URL}/user/get-completed-Failed-chats?page=${currentCompletedFailedChatPage}`;
            }

            const response = await fetch(completedChatsApiUrl, { signal });
            const data = await response.json();

            if (data.success) {
                const {
                    CompletedFailedChats,
                    Chats,
                    totalPages,
                    totalResults,
                    totalFailedPaidChats,
                    totalFailedTransactions,
                    totalFailedFreeChats,
                    totalFailedChats,
                    totalFailedAmount,
                } = data;

                setCompletedFailedChatsData(
                    CompletedFailedChats || Chats || []
                );
                setTotalCompletedFailedChatsPages(totalPages || 1);
                setTotalCompletedFailedChats(
                    totalResults || totalFailedChats || 0
                );
                setTotalFailedFreeChats(totalFailedFreeChats || 0);
                setTotalFailedPaidChats(totalFailedPaidChats || 0);
                setTotalTransactionsFailedCount(totalFailedTransactions || 0);
                setTotalFailedAmount(totalFailedAmount || 0);
            }
        } catch (error) {
            console.error("Error fetching completed failed chats:", error);
        } finally {
            setIsLoading(false);
        }

        return () => controller.abort();
    };

    const handleCompletedPageChange = (direction) => {
        if (direction === "prev" && currentCompletedFailedChatPage > 1) {
            setCurrentCompletedFailedChatPage(
                currentCompletedFailedChatPage - 1
            );
        } else if (
            direction === "next" &&
            currentCompletedFailedChatPage < totalCompletedFailedChatsPages
        ) {
            setCurrentCompletedFailedChatPage(
                currentCompletedFailedChatPage + 1
            );
        }
    };

    const handleFailedChat = async (chat) => {
        const { user, astrologer, updatedAt } = chat;
        navigate(
            `/dashboard/getRoomChat/${`${chat.user._id}_${chat.astrologer._id}`}`,
            {
                state: { user, astrologer, updatedAt },
            }
        );
    };
    const completedChatDataTable = () => (
        <>
            <div className="astro-table-container pymnts-container">
                <table className="astro-table">
                    <thead className="astro-he">
                        <tr className="astro-bg">
                            <th className="he">User Name</th>
                            <th className="he">User Phone Number</th>
                            <th className="he">Astrologer Name</th>
                            <th className="he">Astrologer Phone Number</th>
                            <th className="he">Session Time(MIN)</th>
                            <th className="he">Chat Cost</th>
                            <th className="he">Date/Time</th>
                            <th className="he">View Chat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {completedFailedChatsData?.map((chat) => (
                            <tr className="astro-rows" key={chat._id}>
                                <td
                                    className="he"
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/user/${chat?.user?._id}`,
                                            {
                                                state: {
                                                    user: {
                                                        _id: chat?.user?._id,
                                                        userName:
                                                            chat?.user
                                                                ?.userName,
                                                        phone_number:
                                                            chat?.user
                                                                ?.phone_number,
                                                    },
                                                },
                                            }
                                        )
                                    }
                                    style={{ color: "blue", cursor: "pointer" }}
                                >
                                    {chat?.user?.userName || "NA"}
                                </td>
                                <td className="he">
                                    {chat?.user?.phone_number || "NA"}
                                </td>
                                <td
                                    className="he"
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/astrologer/${chat?.astrologer?._id}`,
                                            {
                                                state: {
                                                    astrologer:
                                                        chat?.astrologer,
                                                },
                                            }
                                        )
                                    }
                                    style={{ color: "blue", cursor: "pointer" }}
                                >
                                    {chat?.astrologer?.astrologerName || "NA"}
                                </td>
                                <td className="he">
                                    {chat?.astrologer?.phone_number || "NA"}
                                </td>
                                <td className="he">
                                    {chat?.session_time || "NA"}
                                </td>
                                <td className="he">
                                    {chat?.chatCost || "Free"}
                                </td>
                                <td className="he">
                                    {chat?.updatedAt || "NA"}
                                </td>
                                <td className="he">
                                    {
                                        <SiGooglemessages
                                            className="messeges-google-icon"
                                            onClick={() =>
                                                handleFailedChat(chat)
                                            }
                                            size={35}
                                        />
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {!debouncedSearch && (
                <div className="pagination">
                    <button
                        className="pagination-button"
                        onClick={() => handleCompletedPageChange("prev")}
                        disabled={currentCompletedFailedChatPage === 1}
                    >
                        Prev
                    </button>
                    <span className="pagination-info">
                        Page {currentCompletedFailedChatPage} of{" "}
                        {totalCompletedFailedChatsPages}
                    </span>
                    <button
                        className="pagination-button"
                        onClick={() => handleCompletedPageChange("next")}
                        disabled={
                            currentCompletedFailedChatPage ===
                            totalCompletedFailedChatsPages
                        }
                    >
                        Next
                    </button>
                </div>
            )}
        </>
    );

    useEffect(() => {
        getCompletedChats();
    }, [debouncedSearch, currentCompletedFailedChatPage, selectedDate]);

    return (
        <div>
            <div>
                <input
                    type="search"
                    placeholder="Search by Username, Astrologer Name, or Phone Number"
                    className="search-bar"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button className="btn" onClick={() => getCompletedChats()}>
                    {isLoading === true ? "Loading..." : "Reload"}
                </button>
            </div>

            <div className="date-picker-container">
                <h3 className="date-picker-heading">Select a Date</h3>
                <input
                    type="date"
                    value={selectedDate}
                    onChange={(event) => {
                        setSelectedDate(event.target.value);
                        setCurrentCompletedFailedChatPage(1);
                    }}
                    className="date-input"
                />
                <button
                    className="date-clear"
                    onClick={() => {
                        setSelectedDate("");
                        setCurrentCompletedFailedChatPage(1);
                    }}
                >
                    Clear
                </button>

                {selectedDate && (
                    <p className="selected-date-text">{`Selected date: ${selectedDate}`}</p>
                )}
                <div className="count-of-chats">
                    <h3>{`Total Failed Free Chats: ${totalFailedFreeChats}`}</h3>
                    <h3>{`Total Failed Paid Chats: ${totalFailedPaidChats}`}</h3>
                    <h3>{`Total Failed Chats Amount: Rs=${totalFailedAmount}/-`}</h3>
                    <h3>{`Total Failed Chats: ${totalTransactionsFailedCount}`}</h3>
                </div>
            </div>
            {isLoading === true ? <Loader /> : completedChatDataTable()}
        </div>
    );
};

export default CompletedFailedChats;
