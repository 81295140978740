import { useEffect, useState, useRef } from "react";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { SiGooglemessages } from "react-icons/si";
import { refundAmount } from "../api/walletApi";
import WalletPopUp from "../WalletPopUp";
import RefundSuccessPopup from "../RefundSuccessPopup";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../config";
const AstrologerChatWalletTransaction = () => {
    const [currentAllTransactionsPage, setAllTransactionsCurrentPage] =
        useState(1);
    const [totalAllTransactionsPages, setAllTotalTransactionsPages] =
        useState(null);
    const [allTransactionsData, setAllTransactionsData] = useState([]);
    const [totalNumberOfTransactions, setTotalNumberOfTransactions] =
        useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(""); // State for debounced search
    const [callBackData, setCallBackData] = useState({});
    const [popupMessage, setPopupMessage] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const [refundSuccessful, setRefundSuccessful] = useState(false);
    const [successPercentage, setSuccessPercentage] = useState();
    const [selectedDate, setSelectedDate] = useState(""); // State for selected date
    const controllerRef = useRef(null);
    const navigate = useNavigate();

    // Debounce effect for search input
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 500); // 500ms debounce time

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        controllerRef.current = new AbortController();
        getWalletAllTransactions(controllerRef.current.signal);
    }, [currentAllTransactionsPage, debouncedSearchTerm, selectedDate]); // Include selectedDate in dependencies

    const getWalletAllTransactions = async (signal) => {
        try {
            const url = selectedDate
                ? `${BASE_URL}/wallet/chat-transactions-by-date?date=${selectedDate}&page=${currentAllTransactionsPage}`
                : debouncedSearchTerm
                ? `${BASE_URL}/wallet/search-astrologer-chat-transactions?search=${debouncedSearchTerm}&page=${currentAllTransactionsPage}`
                : `${BASE_URL}/wallet/chat-transactions?page=${currentAllTransactionsPage}`;

            const response = await fetch(url, { signal });
            const data = await response.json();
            const { transactions, pagination, success } = data;

            if (success) {
                if (pagination) {
                    const { totalPages, totalTransactions } = pagination;
                    setTotalNumberOfTransactions(totalTransactions);
                    setAllTotalTransactionsPages(totalPages);
                }
                setAllTransactionsData(transactions);
            }
        } catch (error) {
            console.error("Error fetching transactions:", error);
        }
    };

    const handleToChangeEdit = (data) => {
        setCallBackData(data);
        setPopupMessage(`Do you want to Refund this Amount?`);
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
        setPopupMessage("");
    };

    const handleRefundSuccess = async (percentage) => {
        try {
            const result = await refundAmount(
                callBackData.id,
                callBackData.userId,
                callBackData.astrologerId,
                callBackData.amount,
                percentage
            );
            setRefundSuccessful(true);
            setSuccessPercentage(percentage);
        } catch (error) {
            console.error("An error occurred: " + error.message);
        }
        closePopup();
    };

    const handleSuccessMessageDismiss = () => {
        setRefundSuccessful(false);
        getWalletAllTransactions();
    };

    const handlePageChange = (direction) => {
        if (direction === "prev" && currentAllTransactionsPage > 1) {
            setAllTransactionsCurrentPage(currentAllTransactionsPage - 1);
        } else if (
            direction === "next" &&
            currentAllTransactionsPage < totalAllTransactionsPages
        ) {
            setAllTransactionsCurrentPage(currentAllTransactionsPage + 1);
        }
    };

    const handleViewChat = (chat) => {
        console.log(chat);
        const { userId, astrologerId, userName, astrologer, updatedAt } = chat;
        const user = { userName };
        navigate(`/dashboard/getRoomChat/${`${userId}_${astrologerId}`}`, {
            state: { user, astrologer, updatedAt },
        });
    };

    const WalletAllTransactionsTable = () => (
        <>
            <div className="astro-table-container pymnts-container">
                <table className="astro-table">
                    <thead className="astro-he">
                        <tr className="astro-bg">
                            <th className="he">Username</th>
                            <th className="he">User Number</th>
                            <th className="he">Astrologer Name</th>
                            <th className="he">Astrologer Number</th>
                            <th className="he">
                                Pre-consultation Wallet Balance
                            </th>
                            <th className="he">
                                Post-consultation Wallet Balance
                            </th>
                            <th className="he">Type</th>
                            <th className="he">Date-Time</th>
                            <th className="he">Session Amount</th>
                            <th className="he">Session Time(MIN)</th>
                            <th className="he">View Chat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allTransactionsData.map((callBackData) => (
                            <tr className="astro-rows" key={callBackData.id}>
                                <td
                                    className="he"
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/user/${callBackData?.userId}`,
                                            {
                                                state: {
                                                    user: {
                                                        _id: callBackData?.userId,
                                                        userName:
                                                            callBackData?.userName,
                                                        phone_number:
                                                            callBackData?.userNumber,
                                                    },
                                                },
                                            }
                                        )
                                    }
                                    style={{ color: "blue", cursor: "pointer" }}
                                >
                                    {callBackData?.userName || "Loading..."}
                                </td>
                                <td className="he">
                                    {callBackData.userNumber}
                                </td>
                                <td
                                    className="he"
                                    onClick={() => {
                                        navigate(
                                            `/dashboard/astrologer/${callBackData.astrologerId}`,
                                            {
                                                state: {
                                                    astrologer:
                                                        callBackData.astrologer,
                                                },
                                            }
                                        );
                                    }}
                                    style={{ color: "blue", cursor: "pointer" }}
                                >
                                    {callBackData?.astrologerName ||
                                        "Loading..."}
                                </td>
                                <td className="he">
                                    {callBackData.astrologerNumber}
                                </td>
                                <td className="he">
                                    {callBackData.beforeAstrologerWalletBalance}
                                </td>
                                <td className="he">
                                    {callBackData.afterAstrologerWalletBalance}
                                </td>
                                <td className="he">{callBackData.type}</td>
                                <td className="he wallet-date-time">
                                    {new Date(
                                        callBackData.date
                                    ).toLocaleString()}
                                </td>
                                <td className="he">{callBackData.amount}</td>
                                <td className="he">
                                    {callBackData.session_time}
                                </td>
                                <td>
                                    <SiGooglemessages
                                        className="messeges-google-icon"
                                        onClick={() =>
                                            handleViewChat(callBackData)
                                        }
                                        size={35}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <button
                    className="pagination-button"
                    onClick={() => handlePageChange("prev")}
                    disabled={currentAllTransactionsPage === 1}
                >
                    Prev
                </button>
                <span className="pagination-info">
                    Page {currentAllTransactionsPage} of{" "}
                    {totalAllTransactionsPages}
                </span>
                <button
                    className="pagination-button"
                    onClick={() => handlePageChange("next")}
                    disabled={
                        currentAllTransactionsPage === totalAllTransactionsPages
                    }
                >
                    Next
                </button>
            </div>
            {popupVisible && (
                <WalletPopUp
                    message={popupMessage}
                    onCancel={closePopup}
                    onRefundSuccess={handleRefundSuccess}
                    callBackData={callBackData}
                />
            )}
            {refundSuccessful && (
                <RefundSuccessPopup
                    successPercentage={successPercentage}
                    onClose={handleSuccessMessageDismiss}
                />
            )}
        </>
    );

    return (
        <>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by Username, Astrologer Number, or Astrologer Name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="astro-search-input wlt-input"
                />
            </div>
            <div className="date-picker-container-wallet">
                <div>
                    <h4>Select a Date</h4>
                </div>
                <div className="date-picker-inputs">
                    <input
                        type="date"
                        value={selectedDate}
                        onChange={(event) =>
                            setSelectedDate(event.target.value)
                        }
                        className="date-input"
                    />
                    <div>
                        <button
                            className="date-clear"
                            onClick={() => setSelectedDate("")}
                        >
                            Clear
                        </button>
                    </div>
                </div>
                {selectedDate && (
                    <p className="selected-date-text">{`Selected date: ${selectedDate}`}</p>
                )}
            </div>
            <div>{WalletAllTransactionsTable()}</div>
        </>
    );
};

export default AstrologerChatWalletTransaction;
