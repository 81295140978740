import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import ChatBubble from "../ChatBubble/ChatBubble";
import "./ChatRoomMessages.css";
import { ActiveSectionContext } from "../ActiveSectionContext";
import Loader from "../Loader";
import BASE_URL from "../config";
const ChatRoomMessages = () => {
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const { currentActiveSection, setActiveCurrentSection } =
        useContext(ActiveSectionContext);

    const { user, astrologer, updatedAt } = location.state || {
        user: null,
        astrologer: null,
        updatedAt: null,
    };

    const { chatroomId } = useParams();
    // console.log(chatroomId);

    useEffect(() => {
        const abortController = new AbortController();
        const { signal } = abortController;

        const fetchChatHistory = async () => {
            try {
                // console.log("chatroomId",chatroomId)
                const response = await axios.get(
                    `${BASE_URL}/chatRoute/messages/${chatroomId}`,
                    { signal }
                );
                const { chatroom } = response.data;
                const { messages } = chatroom;
                // console.log(response);
                // console.log(messages);
                setMessages(messages);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching chat history:", error);
                setLoading(false);
            }
        };

        fetchChatHistory();

        return () => {
            abortController.abort();
        };
    }, [chatroomId]);

    if (loading) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    const getBacktoChatHistory = () => {
        //setActiveCurrentSection("Chat History");
        navigate(-1);
    };

    return (
        <div className="chat-history-main-container">
            <button
                className="user-back-button"
                onClick={() => getBacktoChatHistory()}
            >
                <IoMdArrowRoundBack size={23} className="back-arrow" />
            </button>
            <h2 className="chat-history-heading">
                Astrologer-User Chat History
            </h2>
            <div className="chat-profile-container">
                <p>{`User: ${
                    user?.userName === undefined ? "NA" : user?.userName
                }`}</p>
                <p>{`Astrologer: ${
                    astrologer?.astrologerName === undefined
                        ? "NA"
                        : astrologer?.astrologerName
                }`}</p>
            </div>
            <div className="chat-room">
                {messages?.length > 0 ? (
                    messages.map((msg) => (
                        <ChatBubble
                            key={msg._id}
                            message={msg}
                            user={user}
                            messageSendDate={updatedAt}
                            astrologer={astrologer}
                        />
                    ))
                ) : (
                    <h1 className="empty-msg">No Chat history</h1>
                )}
            </div>
        </div>
    );
};

export default ChatRoomMessages;
