import { useEffect, useState } from "react";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";
import { SiGooglemessages } from "react-icons/si";

import BASE_URL from "../config";
const CompletedChats = () => {
    const [completedChatsData, setCompletedChatsData] = useState([]);
    const [currentCompletedChatPage, setCurrentCompletedChatPage] = useState(1);
    const [totalCompletedChatsPages, setTotalCompletedChatsPages] =
        useState(null);
    const [selectedDate, setSelectedDate] = useState("");
    const [totalCompletedChats, setTotalCompletedChats] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [totalFreeChats, setTotalFreeChats] = useState(0);
    const [totalPaidChats, setTotalPaidChats] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalTransactionsCount, setTotalTransactionsCount] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(searchQuery);
        }, 500);
        return () => clearTimeout(handler);
    }, [searchQuery]);

    const getCompletedChats = async () => {
        const controller = new AbortController();
        const signal = controller?.signal;

        try {
            setIsLoading(true);

            let completedChatsApiUrl;

            if (selectedDate) {
                completedChatsApiUrl = `${BASE_URL}/user/get-completedChats-by-date?date=${selectedDate}&page=${currentCompletedChatPage}`;
            } else if (debouncedSearch.trim()) {
                completedChatsApiUrl = `${BASE_URL}/user/search-completed-chats?search=${debouncedSearch}`;
            } else {
                completedChatsApiUrl = `${BASE_URL}/user/get-completed-chats?page=${currentCompletedChatPage}`;
            }

            const response = await fetch(completedChatsApiUrl, { signal });
            const data = await response.json();

            if (data.success) {
                const {
                    CompletedChats,
                    Chats,
                    totalPages,
                    totalResults,
                    totalPaidChats,
                    totalTransactions,
                    totalFreeChats,
                    totalChats,
                    totalAmount,
                } = data;

                setCompletedChatsData(CompletedChats || Chats || []);
                setTotalCompletedChatsPages(totalPages || 1);
                setTotalCompletedChats(totalResults || totalChats || 0);
                setTotalFreeChats(totalFreeChats || 0);
                setTotalPaidChats(totalPaidChats || 0);
                setTotalTransactionsCount(totalTransactions || 0);
                setTotalAmount(totalAmount || 0);
            }
        } catch (error) {
            console.error("Error fetching completed chats:", error);
        } finally {
            setIsLoading(false);
        }

        return () => controller.abort();
    };

    const handleCompletedPageChange = (direction) => {
        if (direction === "prev" && currentCompletedChatPage > 1) {
            setCurrentCompletedChatPage(currentCompletedChatPage - 1);
        } else if (
            direction === "next" &&
            currentCompletedChatPage < totalCompletedChatsPages
        ) {
            setCurrentCompletedChatPage(currentCompletedChatPage + 1);
        }
    };

    const handleViewChat = (chat) => {
        const { _id: roomId, user, astrologer, updatedAt } = chat;
        navigate(`/dashboard/getRoomChat/${`${user._id}_${astrologer._id}`}`, {
            state: { user, astrologer, updatedAt },
        });
    };
    const completedChatDataTable = () => (
        <>
            <div className="astro-table-container pymnts-container">
                <table className="astro-table">
                    <thead className="astro-he">
                        <tr className="astro-bg">
                            <th className="he">User Name</th>
                            <th className="he">User Phone Number</th>
                            <th className="he">Astrologer Name</th>
                            <th className="he">Astrologer Phone Number</th>
                            <th className="he">Session Time(MIN)</th>
                            <th className="he">Chat Cost</th>
                            <th className="he">Date/Time</th>
                            <th className="he">View Chat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {completedChatsData?.map((chat) => (
                            <tr className="astro-rows" key={chat._id}>
                                <td
                                    className="he"
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/user/${chat?.user?._id}`,
                                            {
                                                state: {
                                                    user: {
                                                        _id: chat?.user?._id,
                                                        userName:
                                                            chat?.user
                                                                ?.userName,
                                                        phone_number:
                                                            chat?.user
                                                                ?.phone_number,
                                                    },
                                                },
                                            }
                                        )
                                    }
                                    style={{ color: "blue", cursor: "pointer" }}
                                >
                                    {chat?.user?.userName || "NA"}
                                </td>
                                <td className="he">
                                    {chat?.user?.phone_number || "NA"}
                                </td>
                                <td
                                    className="he"
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/astrologer/${chat?.astrologer?._id}`,
                                            {
                                                state: {
                                                    astrologer:
                                                        chat?.astrologer,
                                                },
                                            }
                                        )
                                    }
                                    style={{ color: "blue", cursor: "pointer" }}
                                >
                                    {chat?.astrologer?.astrologerName || "NA"}
                                </td>
                                <td className="he">
                                    {chat?.astrologer?.phone_number || "NA"}
                                </td>
                                <td className="he">
                                    {chat?.session_time || "NA"}
                                </td>
                                <td className="he">
                                    {chat?.chatCost || "Free"}
                                </td>
                                <td className="he">
                                    {chat?.updatedAt || "NA"}
                                </td>
                                <td className="he">
                                    <SiGooglemessages
                                        className="messeges-google-icon"
                                        onClick={() => handleViewChat(chat)}
                                        size={35}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {!debouncedSearch && (
                <div className="pagination">
                    <button
                        className="pagination-button"
                        onClick={() => handleCompletedPageChange("prev")}
                        disabled={currentCompletedChatPage === 1}
                    >
                        Prev
                    </button>
                    <span className="pagination-info">
                        Page {currentCompletedChatPage} of{" "}
                        {totalCompletedChatsPages}
                    </span>
                    <button
                        className="pagination-button"
                        onClick={() => handleCompletedPageChange("next")}
                        disabled={
                            currentCompletedChatPage ===
                            totalCompletedChatsPages
                        }
                    >
                        Next
                    </button>
                </div>
            )}
        </>
    );

    useEffect(() => {
        getCompletedChats();
    }, [debouncedSearch, currentCompletedChatPage, selectedDate]);

    return (
        <div>
            <div>
                <input
                    type="search"
                    placeholder="Search by Username, Astrologer Name, or Phone Number"
                    className="search-bar"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button className="btn" onClick={() => getCompletedChats()}>
                    {isLoading === true ? "Loading..." : "Reload"}
                </button>
            </div>

            <div className="date-picker-container">
                <h3 className="date-picker-heading">Select a Date</h3>
                <input
                    type="date"
                    value={selectedDate}
                    onChange={(event) => {
                        setSelectedDate(event.target.value);
                        setCurrentCompletedChatPage(1);
                    }}
                    className="date-input"
                />
                <button
                    className="date-clear"
                    onClick={() => {
                        setSelectedDate("");
                        setCurrentCompletedChatPage(1);
                    }}
                >
                    Clear
                </button>

                {selectedDate && (
                    <p className="selected-date-text">{`Selected date: ${selectedDate}`}</p>
                )}
                <div className="count-of-chats">
                    <h3>{`Total Free Chats: ${totalFreeChats}`}</h3>
                    <h3>{`Total Paid Chats: ${totalPaidChats}`}</h3>
                    <h3>{`Total Chats Amount: Rs=${totalAmount}/-`}</h3>
                    <h3>{`Total Chats: ${totalTransactionsCount}`}</h3>
                </div>
            </div>
            {isLoading === true ? <Loader /> : completedChatDataTable()}
        </div>
    );
};

export default CompletedChats;
