import { useEffect, useState } from "react";
import BASE_URL from "../config";
import './UserTransactions.css'
const UserTransactions = ({ userId }) => {
  const [currentTransactionPage, setCurrentTransactionPage] = useState(1);
  const [totalTransactionsPages, setTotalTransactionsPages] = useState(1);
  const [transactionsData, setTransactionsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [failuresCount,setFailuresCount] = useState(0)
  const [successCount, setSuccessCount] = useState(0)
  const [totalFailuresAmount, setTotalFailuresAmount]= useState(0)
  const [ totalSuccessAmount, setTotalSuccessAmount] = useState(0)
  const getUserTransactions = async () => {
    const controller = new AbortController(); // Create an AbortController
    const signal = controller.signal;

    setLoading(true);
    setError(null);

    const userTransactionsApiUrl = `${BASE_URL}/transactions/get-trans-data/${userId}?page=${currentTransactionPage}`;

    try {
      const response = await fetch(userTransactionsApiUrl, { signal });

      // Check for non-2xx HTTP status codes
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("user transactions data", data);
      const { pagination, success, transactions, totalTransactionsDetails } = data;

      const {successCount,
        totalSuccessAmount,
        failureCount,
        totalFailureAmount} = totalTransactionsDetails 
      if (!success) {
        throw new Error("Failed to fetch transactions.");
      }

      const { totalPages } = pagination;
      setTotalTransactionsPages(totalPages);
      setTransactionsData(transactions);

      setFailuresCount(   failureCount,) 
      setTotalFailuresAmount( totalFailureAmount)
    
      setSuccessCount( successCount)
      setTotalSuccessAmount(totalSuccessAmount)
  
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Error fetching transactions:", err);
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }

    return () => {
      // Cleanup: abort any ongoing fetch request if component unmounts or request is replaced
      controller.abort();
    };
  };

  const handlePageChange = (direction) => {
    if (direction === "prev" && currentTransactionPage > 1) {
      setCurrentTransactionPage(currentTransactionPage - 1);
    } else if (
      direction === "next" &&
      currentTransactionPage < totalTransactionsPages
    ) {
      setCurrentTransactionPage(currentTransactionPage + 1);
    }
  };

  const renderUserTransactionsTable = () => (
    <>
      <div className="astro-table-container pymnts-container">
        <table className="data-table">
          <thead>
            <tr>
              <th>Date & Time</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Coupon Amount</th>
            </tr>
          </thead>
          <tbody>
            {transactionsData.map((transaction, index) => (
              <tr className="astro-rows" key={index}>
                <td>
                  {new Date(transaction.date).toLocaleString(undefined, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
                </td>
                <td>{transaction.status}</td>
                <td>{transaction.amount}</td>
                <td>{transaction?.couponAmount || "NA"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className="pagination-button"
          onClick={() => handlePageChange("prev")}
          disabled={currentTransactionPage === 1 || loading}
        >
          Prev
        </button>
        <span className="pagination-info">
          Page {currentTransactionPage} of {totalTransactionsPages}
        </span>
        <button
          className="pagination-button"
          onClick={() => handlePageChange("next")}
          disabled={
            currentTransactionPage === totalTransactionsPages || loading
          }
        >
          Next
        </button>
      </div>
    </>
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getUserTransactions(signal);

    return () => {
      controller.abort();
    };
  }, [currentTransactionPage]);

  return (
    <div>

      <div className="Transactions-container"> 
     
        <h4>{`Total Transactions: ${successCount + failuresCount} | Amount: ${totalSuccessAmount + totalFailuresAmount}`}</h4>
        <h4>{`Total Successful Transactions: ${successCount} | Amount: ${totalSuccessAmount}`}</h4>
        <h4>{`Total Failure Transactions: ${failuresCount} | Amount: ${totalFailuresAmount}`}</h4>
      </div>
      {loading && <h1>Loading transactions...</h1>}

      {!loading && !error && transactionsData.length > 0
        ? renderUserTransactionsTable()
        : !loading && <h1>There are no transactions!</h1>}
    </div>
  );
};

export default UserTransactions;
