import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./AstrologerStatusOffAndOn.css";
import Switch from "@mui/material/Switch";
import BASE_URL from "../config";

// import Loader from "../Loader";

const AstrologerStatusOffAndOn = () => {
    const location = useLocation();
    const { astrologer } = location.state || {};
    const [checked, setChecked] = React.useState();
    const [astrologerStatusTo, setAstrologerStatusTo] = useState("");
    const [astrologerStatusFromcolor, setAstrologerStatusFromcolor] =
        useState();
    const [loading, setLoading] = useState(false);

    const fetchStatus = async (astrologer) => {
        setLoading(true);
        try {
            const astrologerId = astrologer?._id;
            const response = await fetch(
                `${BASE_URL}/astrologer/getOnlineStatus/${astrologerId}`
            );
            const result = await response.json();
            if (result.success) {
                if (result.astrologer?.status === "available") {
                    setChecked(true);
                    // console.log("line 30", result?.astrologer.status, result.astrologer.astrologerName);
                    setAstrologerStatusFromcolor("greenColorHeading");
                    setAstrologerStatusTo("available");
                } else if (result.astrologer?.status === "busy") {
                    setAstrologerStatusFromcolor("redColorHeading");
                    setAstrologerStatusTo("busy");
                    setChecked(true);
                } else {
                    setChecked(false);
                    setAstrologerStatusFromcolor("darkColorHeading");
                    setAstrologerStatusTo("offline");
                }
            }
        } catch (error) {
            console.error("Error fetching status", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchStatus(astrologer);
    }, [astrologer]);

    const handleChange = async (event) => {
        let astrologerStatus = event.target.checked ? "available" : "offline";
        try {
            const response = await fetch(
                `${BASE_URL}/astrologer/updateOnlineStatus`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        astrologerId: astrologer?._id,
                        status: astrologerStatus,
                    }),
                }
            );
            const result = await response.json();
            if (result.success) {
                window.location.reload();
                setChecked((preview) => !preview);
            }
        } catch (error) {
            console.error("Error updating status", error);
            window.location.reload();
        }
    };

    return (
        <div>
        {loading ? (
          <h1>Loading...</h1>) : (
            <>
            <h1 className={`${astrologerStatusFromcolor}`}>{astrologerStatusTo}</h1>
          <Switch
            className="customSwitchbtnbtn"
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
          </>
          )}
    </div>
    );
};

export default AstrologerStatusOffAndOn;
