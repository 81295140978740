import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import "./PendingChats.css";
import BASE_URL from "../config";
const PendingChats = () => {
    const navigate = useNavigate();

    const [currentPendingPage, setCurrentPendingPage] = useState(1);
    const [pendingChatsData, setPendingChatsData] = useState([]);
    const [totalPendingChatsPages, setPendingChatsPages] = useState(null);
    const [totalChats, setTotalNumberOfChats] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");

    const getPendingChats = async (controller) => {
        setIsLoading(true);

        let apiUrl = `${BASE_URL}/astrologer/get-allWaiting-Data?page=${currentPendingPage}`;
        if (selectedDate) {
            apiUrl = `${BASE_URL}/astrologer/get-chats-by-date?date=${selectedDate}&page=${currentPendingPage}`;
        }
        const { signal } = controller;
        const response = await fetch(apiUrl, { signal });
        const data = await response.json();

        if (response.ok === true) {
            const { totalChats, totalPages, chats } = data;
            setPendingChatsData(chats);
            setPendingChatsPages(totalPages);
            setTotalNumberOfChats(totalChats);
        }
        setIsLoading(false);
    };

    const searchChats = async (controller) => {
        if (!searchQuery.trim()) {
            setIsSearching(false);
            return;
        }
        setIsSearching(true);
        const { signal } = controller;
        const searchApiUrl = `${BASE_URL}/astrologer/search-waiting-chats?search=${searchQuery}`;
        const response = await fetch(searchApiUrl, { signal });
        const data = await response.json();
        if (data.success) {
            setSearchResults(data.chats);
        } else {
            setSearchResults([]);
        }
        setIsSearching(false);
    };

    const handlePendingChatsPage = (direction) => {
        if (direction === "prev" && currentPendingPage > 1) {
            setCurrentPendingPage(currentPendingPage - 1);
        } else if (
            direction === "next" &&
            currentPendingPage < totalPendingChatsPages
        ) {
            setCurrentPendingPage(currentPendingPage + 1);
        }
    };

    const getDurationFromUpdatedAt = (updatedAt) => {
        if (!updatedAt) return "NA";

        const updatedDateTime = new Date(updatedAt);
        const now = new Date();
        const diffMs = now - updatedDateTime;

        if (diffMs < 0) return "0 min";

        const diffMinutes = Math.floor(diffMs / (1000 * 60));
        const diffHours = Math.floor(diffMinutes / 60);
        const diffDays = Math.floor(diffHours / 24);

        const remainingMinutes = diffMinutes % 60;
        const remainingHours = diffHours % 24;

        let duration = "";
        if (diffDays > 0)
            duration += `${diffDays} day${diffDays > 1 ? "s" : ""} `;
        if (diffHours > 0 || diffDays > 0)
            duration += `${remainingHours} hr${remainingHours > 1 ? "s" : ""} `;
        duration += `${remainingMinutes} min${remainingMinutes > 1 ? "s" : ""}`;

        return duration.trim();
    };

    const handleMove = (chatId) => {
        navigate(`/dashboard/super-astrologers/${chatId}`);
    };

    const regularChatDataTable = (data) => (
        <div className="astro-table-container pymnts-container">
            <table className="astro-table">
                <thead className="astro-he">
                    <tr className="astro-bg">
                        <th className="he">User Name</th>
                        <th className="he">Place of Birth</th>
                        <th className="he">Astrologer Name</th>
                        <th className="he">Astrologer Status</th>
                        <th className="he">Previous Astrologer</th>
                        <th className="he">User PhoneNumber</th>
                        <th className="he">Astrologer PhoneNumber</th>
                        <th className="he">Cost/min</th>
                        <th className="he">Time Duration</th>
                        <th className="he">Waiting Duration (min)</th>
                        <th className="he">Move</th>
                        <th className="he">Type</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((chat, index) => (
                        <tr className="astro-rows" key={chat._id}>
                            <td
                                className="he"
                                onClick={() =>
                                    navigate(
                                        `/dashboard/user/${chat?.userId}`,
                                        {
                                            state: {
                                                user: {
                                                    _id: chat?.userId,
                                                    userName: chat?.userName,
                                                    phone_number: chat?.from,
                                                },
                                            },
                                        }
                                    )
                                }
                                style={{ color: "blue", cursor: "pointer" }}
                            >
                                {chat?.userName || "NA"}
                            </td>
                            <td className="he">{chat?.place_of_birth}</td>
                            <td
                                className="he"
                                onClick={() =>
                                    navigate(
                                        `/dashboard/astrologer/${chat?.astrologerId?._id}`,
                                        {
                                            state: {
                                                astrologer: chat?.astrologerId,
                                            },
                                        }
                                    )
                                }
                                style={{ color: "blue", cursor: "pointer" }}
                            >
                                {chat?.cost === "free" ? (
                                    <div className="view-container">
                                        <button className="view-button">
                                            View
                                        </button>
                                        <div className="tooltip">
                                            {chat.availableAstrologers?.map(
                                                (astro, astroIndex) => (
                                                    <div
                                                        key={astroIndex}
                                                        className="tooltip-row"
                                                    >
                                                        <span className="tooltip-name">
                                                            {astro.astrologerName ||
                                                                "NA"}
                                                        </span>
                                                        <span className="tooltip-phone">
                                                            {astro.phone_number ||
                                                                "NA"}
                                                        </span>
                                                        <span className="tooltip-phone">
                                                            {astro.status ||
                                                                "NA"}
                                                        </span>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    chat?.astrologerId?.astrologerName || "NA"
                                )}
                            </td>

                            <td className="he">
                                {chat?.astrologerId?.status || "NA"}
                            </td>
                            <td className="he">
                                {chat?.prevAstrologerId || "NA"}
                            </td>
                            <td className="he">{chat?.from || "NA"}</td>
                            <td className="he">
                                {chat?.cost === "free" ? (
                                    <div className="view-container">
                                        <button className="view-button">
                                            View
                                        </button>
                                        <div className="tooltip">
                                            {chat.availableAstrologers?.map(
                                                (astro, astroIndex) => (
                                                    <div
                                                        key={astroIndex}
                                                        className="tooltip-row"
                                                    >
                                                        <span className="tooltip-name">
                                                            {astro.astrologerName ||
                                                                "NA"}
                                                        </span>
                                                        <span className="tooltip-phone">
                                                            {astro.phone_number ||
                                                                "NA"}
                                                        </span>
                                                        <span className="tooltip-phone">
                                                            {astro.status ||
                                                                "NA"}
                                                        </span>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    chat?.to || "NA"
                                )}
                            </td>
                            <td className="he">{chat?.cost || "NA"}</td>
                            <td className="he">
                                {chat?.time_duration
                                    ? `${chat.time_duration} min`
                                    : "NA"}
                            </td>
                            <td className="he">
                                {getDurationFromUpdatedAt(chat.updatedAt)}
                            </td>
                            <td className="he">
                                <button
                                    className="move-button"
                                    onClick={() => handleMove(chat._id)}
                                >
                                    Move
                                </button>
                            </td>
                            <td className="he">{chat.type}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    const reloadPendingChats = () => {
        const controller = new AbortController();
        getPendingChats(controller);
    };

    useEffect(() => {
        const controller = new AbortController();
        if (!isSearching) getPendingChats(controller);
        return () => controller.abort();
    }, [currentPendingPage, selectedDate]);

    useEffect(() => {
        const controller = new AbortController();
        const debounceTimer = setTimeout(() => {
            searchChats(controller);
        }, 300); // Debounce time: 300ms
        return () => {
            clearTimeout(debounceTimer);
            controller.abort();
        };
    }, [searchQuery]);

    return (
        <div>
            <div>
                <input
                    type="search"
                    placeholder="Search by Username, Astrologer Name, or Phone Number"
                    className="search-bar"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button className="btn" onClick={reloadPendingChats}>
                    {isLoading ? "Loading..." : "Reload"}
                </button>
            </div>

            <div className="date-picker-container">
                <h3 className="date-picker-heading">Select a Date</h3>
                <input
                    type="date"
                    value={selectedDate}
                    onChange={(event) => setSelectedDate(event.target.value)}
                    className="date-input"
                />
                <button
                    className="date-clear"
                    onClick={() => setSelectedDate("")}
                >
                    Clear
                </button>
                {selectedDate && (
                    <p className="selected-date-text">{`Selected date: ${selectedDate}`}</p>
                )}
            </div>

            {isSearching ? (
                pendingChatsData.length > 0 ? (
                    regularChatDataTable(searchResults)
                ) : (
                    <h1>Currently No Pending Requests are there!</h1>
                )
            ) : isLoading === true ? (
                <Loader />
            ) : pendingChatsData.length > 0 ? (
                regularChatDataTable(pendingChatsData)
            ) : (
                <h1>Current No Pending Requests are there!</h1>
            )}

            {!isSearching && (
                <div className="pagination">
                    <button
                        className="pagination-button"
                        onClick={() => handlePendingChatsPage("prev")}
                        disabled={currentPendingPage === 1}
                    >
                        Prev
                    </button>
                    <span className="pagination-info">
                        Page {currentPendingPage} of {totalPendingChatsPages}
                    </span>
                    <button
                        className="pagination-button"
                        onClick={() => handlePendingChatsPage("next")}
                        disabled={currentPendingPage === totalPendingChatsPages}
                    >
                        Next
                    </button>
                </div>
            )}
        </div>
    );
};

export default PendingChats;
