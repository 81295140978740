import "./Astrologers.css";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { FaCrown } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";
import BASE_URL from "../config";
import DefaultProfile from "../Assets/DefaultProfile.ico";

const Astrologers = () => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [globalSearch, setGlobalSearch] = useState(false);
    const [busyCount, setBusyCount] = useState(0);
    const [availableCount, setAvailableCount] = useState(0);
    const [offlineCount, setOfflineCount] = useState(0);
    const [totalAstrologers, setTotalAstrologers] = useState(0);
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 30;

    const [debounceTimer, setDebounceTimer] = useState(null);
    const [controller, setController] = useState(null);

    const fetchData = async (page, search = "") => {
        setLoading(true);

        if (controller) {
            controller.abort();
        }

        const newController = new AbortController();
        setController(newController);

        try {
            if (globalSearch && search) {
                const response = await fetch(
                    `${BASE_URL}/astrologer/search-astrologers?search=${search}`,
                    { signal: newController?.signal }
                );
                const result = await response.json();

                setData(result.astrologers);
                setTotalPages(1);
            } else {
                const response = await fetch(
                    `${BASE_URL}/astrologer/get-astrologers?page=${page}`,
                    { signal: newController?.signal }
                );
                const result = await response.json();
                const { statusCounts } = result;
                const { busy, offline, available } = statusCounts;
                console.log(statusCounts);
                setData(result.astrologers);
                setTotalAstrologers(result.totalAstrologers);
                setTotalPages(result.totalPages || 1);
                setAvailableCount(available);
                setBusyCount(busy);
                setOfflineCount(offline);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!globalSearch) {
            fetchData(currentPage, searchTerm);
        }

        return () => {
            if (controller) {
                controller.abort();
            }
        };
    }, [currentPage, searchTerm]);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    const handleSearchChange = (searchValue) => {
        setSearchTerm(searchValue);

        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }

        const newTimer = setTimeout(() => {
            setCurrentPage(1);
            if (searchValue.trim()) {
                setGlobalSearch(true);
                fetchData(1, searchValue);
            } else {
                setGlobalSearch(false);
                fetchData(1, "");
            }
        }, 2000);

        setDebounceTimer(newTimer);
    };

    const handleToReload = () => {
        fetchData(currentPage);
    };

    const handleNavigateToCategorizedAstrologers = (status) => {
        navigate(`/dashboard/categorizedAstrologer`, { state: { status } });
    };

    return (
        <div className="atsto-container">
            <h1 className="atrologers-heading">Astrologers</h1>
            <div className="atsro-section1">
                <div className="astro-search-container">
                    <input
                        type="text"
                        placeholder="Search by Astrologer name or phone number"
                        className="astro-search-input"
                        value={searchTerm}
                        onInput={(event) =>
                            handleSearchChange(event.target.value)
                        }
                    />
                </div>
                <div className="length-container">
                    <p className="length">
                        Total Astrologers: {totalAstrologers}
                    </p>
                    <p
                        className="length"
                        onClick={() =>
                            handleNavigateToCategorizedAstrologers("available")
                        }
                    >
                        Total Available Astrologers: {availableCount}
                    </p>
                    <p
                        className="length"
                        onClick={() =>
                            handleNavigateToCategorizedAstrologers("busy")
                        }
                    >
                        Total Busy Astrologers: {busyCount}
                    </p>
                    <p
                        className="length"
                        onClick={() =>
                            handleNavigateToCategorizedAstrologers("offline")
                        }
                    >
                        Total Offline Astrologers: {offlineCount}
                    </p>
                    <TfiReload
                        onClick={() => handleToReload()}
                        className="reload-icon"
                        size={30}
                    />
                </div>
            </div>

            <div className="astro-table-container">
                {loading ? (
                    <Loader />
                ) : (
                    <table className="astro-table">
                        <thead className="astro-he">
                            <tr className="astro-bg">
                                <th className="he">Profile</th>
                                <th className="he">Name</th>
                                <th className="he">Phone Number</th>
                                <th className="he">Status</th>
                                <th className="he">Wallet</th>
                                <th className="he">Withdrawal Request</th>
                                <th className="he">View Waiting List</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((astrologer) => (
                                <tr
                                    className="astro-rows"
                                    key={astrologer._id}
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/astrologer/${astrologer._id}`,
                                            {
                                                state: { astrologer },
                                            }
                                        )
                                    }
                                >
                                    <td className="he">
                                        {astrologer.profile_photo !== "" ? (
                                            <img
                                                src={astrologer.profile_photo}
                                                className="astrologers-image"
                                                alt="profile"
                                            />
                                        ) : (
                                            <img
                                                src={DefaultProfile}
                                                className="astrologers-image"
                                                alt="profile"
                                            />
                                        )}
                                    </td>
                                    <td className="he">
                                        {astrologer.role === "super" && (
                                            <FaCrown
                                                style={{
                                                    fontSize: "20px",
                                                    color: "gold",
                                                    marginLeft: "5px",
                                                }}
                                            />
                                        )}{" "}
                                        {astrologer.astrologerName}
                                    </td>
                                    <td className="he">
                                        {astrologer.phone_number}
                                    </td>
                                    <td className="he">{astrologer.status}</td>
                                    <td className="he">
                                        {astrologer.wallet || "0"}
                                    </td>
                                    <td
                                        className={`he ${
                                            astrologer.withdrawlRequest
                                                ? "withdrawlRequest-color"
                                                : ""
                                        }`}
                                    >
                                        {astrologer.withdrawlRequest || "0"}
                                    </td>
                                    <td className="he">
                                        <button
                                            className="move-button"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(
                                                    "/dashboard/astrologer-waitingList",
                                                    {
                                                        state: { astrologer },
                                                    }
                                                );
                                            }}
                                        >
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {!globalSearch && (
                    <div className="pagination">
                        <button
                            className="pagination-button"
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span className="pagination-info">
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            className="pagination-button"
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Astrologers;
