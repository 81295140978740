import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./AstrologerDetails.css";
import axios from "axios";
import AstroPopUp from "./AstroPopUp";
import BasicModal from "../AstrologerEditPopup";
import { IoMdArrowRoundBack } from "react-icons/io";
import AstrologerChatRooms from "../AstrologerChatRooms/AstrologerChatRooms";
import AstrologerWalletMain from "../AstrologerWalletTransactions/AstrologerWalletMain";
import AstrologerLoginHistory from "../AstrologerLoginHistory/AstrologerLoginHistory";
import AstrologerSuccessFullTransactions from "./AstrologerSuccessfullTransactions";
import AstrologerFailureFullTransactions from "./AstrologerFailureTransactions";
import AstrologerPendingTransactions from "./AstrologerPendingTransactions";
import AstrologerChatDuration from "./AstrologerChatDuration";
import BASE_URL from "../config";
import AstrologerStatsFig from "../AstrologerStatsFig/AstrologerStatsFig";
import PreferredTimeTable from "../PreferedTime";
import AstrologerStatusOffAndOn from "../AstrologerStatusOffAndOn/AstrologerStatusOffAndOn";

const defaultImage =
    "https://static.vecteezy.com/system/resources/thumbnails/020/765/399/small_2x/default-profile-account-unknown-icon-black-silhouette-free-vector.jpg";

const AstrologerDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { astrologer } = location.state || {};
    const [isActive, setIsActive] = useState(astrologer?.isActive || false);
    const [pendingTrasactions, setPendingTrasactions] = useState([]);
    const [failureTransactions, setFailureTransactions] = useState([]);
    const [successfullTransactions, setSuccessfullTransactions] = useState([]);
    const [popupVisible, setPopupVisible] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const [confirmAction, setConfirmAction] = useState(null);
    const [activeTab, setActiveTab] = useState("astrologer");
    const [activeTransactionStatus, setActiveTransactionStatus] =
        useState("Pending");
    const [pendingAmount, setPendingAmount] = useState(0);
    const [successAmount, setSuccessAmount] = useState(0);
    const [failureAmount, setFailureAmount] = useState(0);
    // const [checked, setChecked] = React.useState();
    // const [astrologerStatusTo, setAstrologerStatusTo] = useState()
    // const [astrologerStatusFromcolor, setastrologerStatusFromcolor] = useState('')

    // console.log("new data", astrologer);

    let totalAmountOfAstrologers = 0;
    if (activeTransactionStatus === "Pending") {
        totalAmountOfAstrologers = pendingAmount;
    } else if (activeTransactionStatus === "Failure") {
        totalAmountOfAstrologers = failureAmount;
    } else if (activeTransactionStatus === "Success") {
        totalAmountOfAstrologers = successAmount;
    }

    const getAstrologerTransaction = async (controller) => {
        const astrologerId = astrologer._id;
        const astroTransactionApi = `${BASE_URL}/astrologer/getastrologer-transactions/:${astrologerId}`;
        const response = await fetch(astroTransactionApi, {
            signal: controller?.signal,
        });
        const data = await response.json();
        console.log(response);
        const astrologerTransactions = data.astrologer.sort(
            (a, b) => new Date(b.date) - new Date(a.date)
        );
        // console.log(astrologerTransactions)
        const astroFailure = astrologerTransactions.filter(
            (eachAstro) => eachAstro.status === "failure"
        );
        const astroPending = astrologerTransactions.filter(
            (eachAstro) => eachAstro.status === "pending"
        );
        const astroSuccess = astrologerTransactions.filter(
            (eachAstro) => eachAstro.status === "success"
        );
        const TotalFailureAmount = astroFailure.reduce((acc, cur) => {
            return acc + cur.amount;
        }, 0);
        const TotalPendingAmount = astroPending.reduce((acc, cur) => {
            return acc + cur.amount;
        }, 0);
        const TotalSuccessAmount = astroSuccess.reduce((acc, cur) => {
            return acc + cur.amount;
        }, 0);

        setPendingAmount(TotalPendingAmount);
        setFailureAmount(TotalFailureAmount);
        setSuccessAmount(TotalSuccessAmount);
        setPendingTrasactions(astroPending);
        setSuccessfullTransactions(astroSuccess);
        setFailureTransactions(astroFailure);
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller?.signal;

        getAstrologerTransaction(controller);
        // if (astrologer?.status === "available") {
        //   setastrologerStatusFromcolor('greenColorHeading');
        //   setAstrologerStatusTo('available');
        // } else if(astrologer?.status === "busy"){
        //   setastrologerStatusFromcolor('redColorHeading')
        //   setAstrologerStatusTo('busy');
        // }
        // console.log('line 106 data',astrologer?.status)
        // if (astrologer?.status === "available" || astrologer?.status === "busy") {
        //   setChecked(true);
        // } else {
        //   setChecked(false);
        //   setastrologerStatusFromcolor('darkColorHeading');
        //   setAstrologerStatusTo('offline');
        // }
        return () => {
            controller.abort();
        };
    }, [astrologer]);

    if (!astrologer) {
        return <p>No astrologer selected.</p>;
    }

    const updateStatus = async (newIsActive) => {
        try {
            const response = await axios.put(
                `${BASE_URL}/astrologer/updateActive/${astrologer._id}`,
                {
                    isActive: newIsActive,
                }
            );

            if (response.data.success) {
                setIsActive(newIsActive);
            } else {
                setPopupMessage(response.data.message);
                setPopupVisible(true);
            }
        } catch (error) {
            setPopupMessage("Error updating status. Please try again.");
            setPopupVisible(true);
        }
    };

    const handleStatusChange = (newIsActive) => {
        setPopupMessage(
            `Are you sure you want to ${
                newIsActive ? "activate" : "deactivate"
            } the astrologer?`
        );
        setConfirmAction(() => () => updateStatus(newIsActive));
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
        setPopupMessage("");
        setConfirmAction(null);
    };

    const confirmActionHandler = () => {
        if (confirmAction) {
            confirmAction();
        }
        closePopup();
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    // const handleChange = async (event)  => {
    //   setChecked(event.target.checked);
    //   let astrologerStatus = event.target.checked ? "available" : "offline";
    //   try {
    //     const response = await fetch(`${BASE_URL}/astrologer/updateOnlineStatus`, {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify({
    //         astrologerId : astrologer._id,
    //         status: astrologerStatus
    //       }),
    //     });
    //     const result = await response.json();
    //   } catch (error) {

    //   }
    //   // console.log("checked", event.target.checked);
    // };

    return (
        <div className="astro-cnt">
            <div className="astro-details-container">
                <div className="inside-container-astro-details">
                    <div className="image-name">
                        <div className="buttonandphototoggel">
                            <img
                                src={astrologer.profile_photo || defaultImage}
                                className="image"
                                alt="Astrologer"
                            />
                        </div>

                        <div className="name-phone-number">
                            <h2 className="astro-name">
                                {astrologer?.astrologerName}
                            </h2>
                            <h3 className="astro-phone_number">
                                {astrologer?.phone_number}
                            </h3>
                        </div>

                        <div className="buttonandphototoggel">
                            <AstrologerStatusOffAndOn astrologer={astrologer} />
                        </div>

                        <button
                            className="astro-back-button"
                            onClick={() => navigate(-1)}
                        >
                            <IoMdArrowRoundBack
                                size={23}
                                className="back-arrow"
                            />
                        </button>
                    </div>

                    <div className="tabs">
                        <button
                            className={`tab-button ${
                                activeTab === "astrologer" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("astrologer")}
                        >
                            Astrologer
                        </button>
                        <button
                            className={`tab-button ${
                                activeTab === "Preferable-Time" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("Preferable-Time")}
                        >
                            Preferable-Time
                        </button>
                        <button
                            className={`tab-button ${
                                activeTab === "Astrologer Stats" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("Astrologer Stats")}
                        >
                            Astro-Stats
                        </button>

                        <button
                            className={`tab-button ${
                                activeTab === "transactions" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("transactions")}
                        >
                            Transactions
                        </button>

                        <button className="tab-button">
                            <BasicModal
                                astrologer={astrologer}
                                getAstrologerTransaction={
                                    getAstrologerTransaction
                                }
                            />
                        </button>
                        <button
                            className={`tab-button ${
                                activeTab === "Chat History" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("Chat History")}
                        >
                            Chat History
                        </button>
                        <button
                            className={`tab-button ${
                                activeTab === "Wallet" ? "active" : ""
                            }`}
                            onClick={() => handleTabClick("Wallet")}
                        >
                            Wallet
                        </button>
                    </div>

                    {activeTab === "astrologer" && (
                        <div className="">
                            <table className="astro-details-table">
                                <tbody>
                                    <tr className="single-astro-rows">
                                        <td>
                                            <strong className="astro-properties">
                                                Category:
                                            </strong>
                                        </td>
                                        <td>{astrologer?.category}</td>
                                    </tr>
                                    <tr className="single-astro-rows">
                                        <td>
                                            <strong className="astro-properties">
                                                Known Languages:
                                            </strong>
                                        </td>
                                        <td>
                                            {astrologer.known_languages?.join(
                                                ","
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="single-astro-rows">
                                        <td>
                                            <strong className="astro-properties">
                                                Expert In:
                                            </strong>
                                        </td>
                                        <td>
                                            {astrologer.expertIn?.length > 0
                                                ? astrologer.expertIn?.join(
                                                      ", "
                                                  )
                                                : "N/A"}
                                        </td>
                                    </tr>
                                    <tr className="single-astro-rows">
                                        <td>
                                            <strong className="astro-properties">
                                                Experience:
                                            </strong>
                                        </td>
                                        <td>{astrologer?.experience}</td>
                                    </tr>
                                    <tr className="single-astro-rows">
                                        <td>
                                            <strong className="astro-properties">
                                                Cost:
                                            </strong>
                                        </td>
                                        <td>
                                            Rs {astrologer?.cost} per minute
                                        </td>
                                    </tr>
                                    <tr className="single-astro-rows">
                                        <td>
                                            <strong className="astro-properties">
                                                Wallet:
                                            </strong>
                                        </td>
                                        <td>Rs {astrologer?.wallet} </td>
                                    </tr>
                                    <tr className="single-astro-rows">
                                        <td>
                                            <strong className="astro-properties">
                                                Order Bookings:
                                            </strong>
                                        </td>
                                        <td>{astrologer?.order_bookings}</td>
                                    </tr>
                                    <tr className="single-astro-rows">
                                        <td>
                                            <strong className="astro-properties">
                                                Status:
                                            </strong>
                                        </td>
                                        <td>{astrologer?.status}</td>
                                    </tr>
                                    <tr className="single-astro-rows">
                                        <td>
                                            <strong className="astro-properties">
                                                OnBoard Date:
                                            </strong>
                                        </td>
                                        <td>
                                            {new Date(
                                                astrologer?.createdAt
                                            ).toLocaleDateString()}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="buttons-active-inactive">
                                <button
                                    className="active-button"
                                    onClick={() => handleStatusChange(true)}
                                    disabled={isActive}
                                >
                                    Activate
                                </button>
                                <button
                                    className="inactive-button"
                                    onClick={() => handleStatusChange(false)}
                                    disabled={!isActive}
                                >
                                    Deactivate
                                </button>

                                {popupVisible && (
                                    <AstroPopUp
                                        message={popupMessage}
                                        onConfirm={confirmActionHandler}
                                        onCancel={closePopup}
                                    />
                                )}
                            </div>
                        </div>
                    )}

                    {activeTab === "transactions" && (
                        <div>
                            <div className="transaction-filter-container-row">
                                <div className="filters">
                                    <button
                                        className={`toggle-btn ${
                                            activeTransactionStatus ===
                                            "Pending"
                                                ? "active-transaction-btn"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            setActiveTransactionStatus(
                                                "Pending"
                                            )
                                        }
                                    >
                                        Pending
                                    </button>
                                    <button
                                        className={`toggle-btn ${
                                            activeTransactionStatus ===
                                            "Success"
                                                ? "active-transaction-btn"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            setActiveTransactionStatus(
                                                "Success"
                                            )
                                        }
                                    >
                                        Success
                                    </button>
                                    <button
                                        className={`toggle-btn ${
                                            activeTransactionStatus ===
                                            "Failure"
                                                ? "active-transaction-btn"
                                                : ""
                                        }`}
                                        onClick={() =>
                                            setActiveTransactionStatus(
                                                "Failure"
                                            )
                                        }
                                    >
                                        Failure
                                    </button>
                                    <h1 className="total-amount">
                                        <strong>{`TotalAmount:${totalAmountOfAstrologers}`}</strong>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    )}

                    {activeTab === "Chat History" && (
                        <AstrologerChatRooms astrologerId={astrologer._id} />
                    )}
                    {activeTab === "Wallet" && (
                         
                        <AstrologerWalletMain astrologerId={astrologer._id} />
                    )}

                    {activeTab === "Astrologer Stats" && (
                        <AstrologerStatsFig astrologer={astrologer} />
                    )}
                    {activeTab === "Preferable-Time" && (
                        <PreferredTimeTable astrologerId={astrologer._id} />
                    )}

                    {activeTransactionStatus === "Success" &&
                        activeTab === "transactions" && (
                            <AstrologerSuccessFullTransactions
                                astrologerId={astrologer._id}
                            />
                        )}
                    {activeTransactionStatus === "Failure" &&
                        activeTab === "transactions" && (
                            <AstrologerFailureFullTransactions
                                astrologerId={astrologer._id}
                            />
                        )}
                    {activeTransactionStatus === "Pending" &&
                        activeTab === "transactions" && (
                            <AstrologerPendingTransactions
                                astrologerId={astrologer._id}
                            />
                        )}
                </div>
            </div>
        </div>
    );
};

export default AstrologerDetails;
