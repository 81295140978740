import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SiGooglemessages } from "react-icons/si";
import Loader from "../Loader";
import BASE_URL from "../config";
const ChatHistory = () => {
    const navigate = useNavigate();
    const [chatHistoryRoomsData, setChatHistoryRoomsData] = useState([]);
    const [totalChatHistoryPages, setTotalChatHistoryPages] = useState(null);
    const [totalChatHistoryCount, setTotalChatHistoryCount] = useState(null);
    const [currentChatHistoryPage, setCurrentChatHistoryPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");

    const abortController = new AbortController();
    const signal = abortController?.signal;

    const getChatHistoryData = async () => {
        setIsLoading(true);
        const chatHistoryApiUrl = `${BASE_URL}/chatRoute/get-chat-rooms?page=${currentChatHistoryPage}`;
        const response = await fetch(chatHistoryApiUrl);
        const data = await response.json();
        if (response.ok === true) {
            const { chatRooms, totalChatRooms, totalPages } = data;
            setChatHistoryRoomsData(chatRooms);
            setTotalChatHistoryPages(totalPages);
            setTotalChatHistoryCount(totalChatRooms);
        }
        setIsLoading(false);
    };

    // Fetch search results with pagination
    const getSearchResults = async () => {
        setIsLoading(true);
        const searchApiUrl = `${BASE_URL}/chatRoute/search-chat-rooms?search=${searchQuery}&page=${currentChatHistoryPage}`;
        const response = await fetch(searchApiUrl, { signal });
        const data = await response.json();

        if (response.ok === true) {
            const { chatRooms, totalChatRooms, pagination } = data;
            const { totalPages } = pagination;
            setChatHistoryRoomsData(chatRooms);
            setTotalChatHistoryPages(totalPages);
            setTotalChatHistoryCount(totalChatRooms);
            setIsSearchActive(true);
        }
        setIsLoading(false);
    };

    // Fetch chat rooms by date with pagination
    const getChatRoomsByDate = async (date) => {
        setIsLoading(true);
        const chatHistoryApiUrl = `${BASE_URL}/chatRoute/get-chat-rooms-by-date?date=${date}&page=${currentChatHistoryPage}`;
        const response = await fetch(chatHistoryApiUrl, { signal });
        const data = await response.json();
        if (response.ok === true) {
            const { chatRooms, totalChatRooms, totalPages } = data;
            setChatHistoryRoomsData(chatRooms);
            setTotalChatHistoryPages(totalPages);
            setTotalChatHistoryCount(totalChatRooms);
        }
        setIsLoading(false);
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);

        setCurrentChatHistoryPage(1);

        if (value.trim() === "") {
            setIsSearchActive(false);
            getChatHistoryData();
        } else {
            getSearchResults();
        }
    };

    const handleViewChat = (chat) => {
        const { chatroomId: chatroomId, user, astrologer, updatedAt } = chat;
        console.log(chatroomId)
        navigate(`/dashboard/getRoomChat/${chatroomId}`, {
            state: { user, astrologer, updatedAt },
        });
    };

    // Handle pagination for both search and regular data
    const handleChatRoomsPage = (direction) => {
        if (direction === "prev" && currentChatHistoryPage > 1) {
            setCurrentChatHistoryPage(currentChatHistoryPage - 1);
        } else if (
            direction === "next" &&
            currentChatHistoryPage < totalChatHistoryPages
        ) {
            setCurrentChatHistoryPage(currentChatHistoryPage + 1);
        }
    };

    // Handle date change
    const handleDateChange = (event) => {
        const date = event.target.value;
        setSelectedDate(date);
        setCurrentChatHistoryPage(1);
        if (date) {
            getChatRoomsByDate(date);
        } else {
            getChatHistoryData();
        }
    };

    // Fetch data when page, search, or date changes
    useEffect(() => {
        if (isSearchActive) {
            getSearchResults();
        } else if (selectedDate) {
            getChatRoomsByDate(selectedDate);
        } else {
            getChatHistoryData();
        }

        return () => {
            abortController.abort();
        };
    }, [currentChatHistoryPage, isSearchActive, selectedDate]);

    const renderChatHistoryTable = () => (
        <>
            <div className="astro-table-container pymnts-container">
                <table className="astro-table">
                    <thead className="astro-he">
                        <tr className="astro-bg">
                            <th className="he">User Name</th>
                            <th className="he">Astrologer Name</th>
                            <th className="he">Astrologer Phone_number</th>
                            <th className="he">User Phone_number</th>
                            <th className="he">Date/Time</th>
                            <th className="he">View Chat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {chatHistoryRoomsData.map((chat) => (
                            <tr className="astro-rows" key={chat._id}>
                                <td
                                    className="he"
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/user/${chat?.user?.userId}`,
                                            {
                                                state: {
                                                    user: {
                                                        _id: chat?.user?.userId,
                                                        userName:
                                                            chat?.user
                                                                ?.userName,
                                                        phone_number:
                                                            chat?.user
                                                                ?.phone_number,
                                                    },
                                                },
                                            }
                                        )
                                    }
                                    style={{ color: "blue", cursor: "pointer" }}
                                >
                                    {chat?.user?.userName || "NA"}
                                </td>
                                <td
                                    className="he"
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/astrologer/${chat?.astrologer?._id}`,
                                            {
                                                state: {
                                                    astrologer:
                                                        chat?.astrologer,
                                                },
                                            }
                                        )
                                    }
                                    style={{ color: "blue", cursor: "pointer" }}
                                >
                                    {chat?.astrologer?.astrologerName || "NA"}
                                </td>
                                <td className="he">
                                    {chat?.astrologer?.phone_number || "NA"}
                                </td>
                                <td className="he">
                                    {chat?.user?.phone_number || "NA"}
                                </td>
                                <td className="he">{chat?.updatedAt}</td>
                                <td className="he">
                                    {chat?.messageCount > 0 ? (
                                        <SiGooglemessages
                                            className="messeges-google-icon"
                                            onClick={() => handleViewChat(chat)}
                                            size={35}
                                        />
                                    ) : (
                                        "No Chat History Found"
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="pagination">
                <button
                    className="pagination-button"
                    onClick={() => handleChatRoomsPage("prev")}
                    disabled={currentChatHistoryPage === 1}
                >
                    Prev
                </button>
                <span className="pagination-info">
                    Page {currentChatHistoryPage} of {totalChatHistoryPages}
                </span>
                <button
                    className="pagination-button"
                    onClick={() => handleChatRoomsPage("next")}
                    disabled={currentChatHistoryPage === totalChatHistoryPages}
                >
                    Next
                </button>
            </div>
        </>
    );

    return (
        <div>
            <div>
                <input
                    type="search"
                    placeholder="Search by Username, Astrologer Name, or Phone Number"
                    className="search-bar"
                    value={searchQuery}
                    onInput={(e) => handleSearchChange(e)}
                />
                <button className="btn" onClick={() => getChatHistoryData()}>
                    {isLoading === true ? "Loading..." : "Reload"}
                </button>
            </div>
            <div className="date-picker-container">
                <h3 className="date-picker-heading">Select a Date</h3>
                <input
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    className="date-input"
                />
                <button
                    className="date-clear"
                    onClick={() => setSelectedDate("")}
                >
                    Clear
                </button>
                {selectedDate && (
                    <p className="selected-date-text">{`Selected date: ${selectedDate}`}</p>
                )}
                <h3>{`Total Chats: ${totalChatHistoryCount}`}</h3>
            </div>
            {isLoading === true ? <Loader /> : renderChatHistoryTable()}
        </div>
    );
};

export default ChatHistory;
