import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactPopUp from "../PopupModel";
import RejectPopup from "../RejectPopModel";
import "./index.css";
import axios from "axios";
import BASE_URL from "../config";
const apiUrlStatusConstants = {
  Initial: "initial",
  Pending: "pending",
  Failed: "failed",
  Successfull: "successfull",
};

const AstrologersRegister = () => {
  const [pendingStatusAstrologers, setPendingStatusAstrologers] = useState([]);
  const [acceptedStatusAstrologers, setAcceptedStatusAstrologers] = useState(
    []
  );
  const [rejectedStatusAstrologers, setRejectedStatusAstrologers] = useState(
    []
  );
  const [statusSection, setStatusSection] = useState("pending");
  const [updateApiStatus, setUpdateApiStatus] = useState("initial");

  const [apiStatus, setApiStatus] = useState(apiUrlStatusConstants["Initial"]);
  // const [activeAstrologerId, setActiveAstrologerId] = useState('')
  // const  [pageNumberSeries,setPerPageData] = useState('')

  // const [pageNumber, setPageNumber] = useState(1)
  const navigate = useNavigate();

  const getRegisteredUsers = async (controller) => {
    setApiStatus(apiUrlStatusConstants["Pending"]);
    const ApiUrl = `${BASE_URL}/register/get-registeredAstrologers`;
    const response = await fetch(ApiUrl);
    const data = await response.json();
    const { newAstrologers } = data;
    // // console.log(newAstrologers);
    if (response.ok === true) {
      setApiStatus(apiUrlStatusConstants["Successfull"]);
      const pendingAstrologers = newAstrologers.filter(
        (eachAstrologer) => eachAstrologer.status === "pending"
      );
      const acceptedAstrologers = newAstrologers.filter(
        (eachAstrologer) => eachAstrologer.status === "accepted"
      );
      const rejectedAstrologers = newAstrologers.filter(
        (eachAstrologer) => eachAstrologer.status === "rejected"
      );

      setAcceptedStatusAstrologers(acceptedAstrologers);
      setPendingStatusAstrologers(pendingAstrologers);
      setRejectedStatusAstrologers(rejectedAstrologers);
    } else {
      setApiStatus(apiUrlStatusConstants["Failed"]);
    }
  };

  useEffect(() => {
    const controller = new AbortController();

    getRegisteredUsers(controller);

    return () => {
      controller.abort();
    };
  }, []);

  const addRegisterAstrologerToAstrologer = async (id) => {
    try {
      const controller = new AbortController();

      const checkResponse = await fetch(
        `${BASE_URL}/register/checkAstrologer/${id}`,
        { signal: controller?.signal }
      );
      const checkData = await checkResponse.json();

      if (checkResponse.ok && checkData.exists) {
        return null;
      }

      // const response = await fetch(`https://api.astroping.com/api/astrologer/save-astrologer`, {
      //   method: 'POST',
      //   headers: {
      //       'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({
      //       email: "vishalraina@superkid.co.in",
      //       password: "qg5WpQ8nEYx9",
      //       astrologerName: astrologerName,
      //       phone_number: phone_number,
      //       category: category,
      //       known_languages: known_languages,
      //       expertIn: expertIn,
      //       experience: experience,
      //       profile_photo: profile_photo,
      //       cost: "",
      //       order_bookings: "",
      //   })

      const response = await fetch(
        `${BASE_URL}/register/addToAstrologer/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            signal: controller?.signal,
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        // console.log('Astrologer added successfully:', data.astrologer);
        return data;
      } else {
        // console.log('Failed to add astrologer:', data.message);
        return null;
      }
    } catch (error) {
      console.error("Error adding astrologer:", error);
      return null;
    }
  };
  const updateAstrologerStatus = async (id, status) => {
    setUpdateApiStatus("pending");
    const controller = new AbortController();

    const apiUrl = `${BASE_URL}/register/update-astrologer-status/${id}`;
    const response = await fetch(apiUrl, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ status }),
      signal: controller?.signal,
    });

    if (response.ok) {
      setUpdateApiStatus("successfull");
      if (status === "accepted") {
        await addRegisterAstrologerToAstrologer(id);
      }
      getRegisteredUsers(controller);
    } else {
      console.error("Failed to update status");
      setUpdateApiStatus("failed");
    }
  };

  const onClickedAstrologerId = (id) => {
    navigate("/dashboard/detailedAstrologer", { state: { astrologerId: id } });
  };

  // const increamentPageNumber = () => {
  //   if (pageNumber < pendingStatusAstrologers.length) {
  //     setPageNumber((prevState) => prevState + 1)
  //   }

  // }
  // const decrementPageNumber = () => {
  //   if (pageNumber > 1) {
  //     setPageNumber((prevState) => prevState - 1)
  //   }
  // }

  const getPendingStatusAstrologer = () => {
    return (
      <div className="register-astro-table-container">
        <table className="register-astrologer-table">
          <thead className="astro-he">
            <tr className="register-rows">
              <th className="he">Name</th>
              <th className="he">Phone Number</th>
              <th className="he">Approval</th>
            </tr>
          </thead>
          <tbody>
            {pendingStatusAstrologers.map((eachAstrologer) => (
              <tr
                className="pending-register-details-rows"
                onClick={() => onClickedAstrologerId(eachAstrologer._id)}
                key={eachAstrologer._id}
              >
                <td className="he">{eachAstrologer.astrologerName}</td>
                <td className="he">{eachAstrologer.phone_number}</td>
                <td className="he">
                  <div className="admin-controls">
                    <ReactPopUp
                      updateApiStatus={updateApiStatus}
                      astrologerId={eachAstrologer._id}
                      onConfirm={(
                        id,
                        status,
                        astrologerName,
                        phone_number,
                        category,
                        known_languages,
                        expertIn,
                        experience,
                        profile_photo,
                        cost
                      ) => updateAstrologerStatus(id, status)}
                    />
                    <RejectPopup
                      updateApiStatus={updateApiStatus}
                      astrologerId={eachAstrologer._id}
                      onConfirm={(
                        id,
                        status,
                        astrologerName,
                        phone_number,
                        category,
                        known_languages,
                        expertIn,
                        experience,
                        profile_photo,
                        cost
                      ) => updateAstrologerStatus(id, status)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <Pagination getPagePerData={getPagePerData}
          pendingStatusAstrologers={pendingStatusAstrologers}
          increamentPageNumber={increamentPageNumber}
          decrementPageNumber={decrementPageNumber}
          pageNumberSeries={pageNumber}
        /> */}
      </div>
    );
  };

  const getApprovedAstrologerDetails = () => {
    return (
      <div className="register-astro-table-container">
        {acceptedStatusAstrologers.length > 0 ? (
          <table className="register-astrologer-table">
            <thead>
              <tr className="register-rows">
                <th className="he">Name</th>
                <th className="he">Phone Number</th>
                <th className="he">Approval</th>
              </tr>
            </thead>
            <tbody>
              {acceptedStatusAstrologers.map((eachAstrologer) => (
                <tr
                  className="register-details-rows"
                  onClick={() => onClickedAstrologerId(eachAstrologer._id)}
                  key={eachAstrologer._id}
                >
                  <td className="he">{eachAstrologer.astrologerName}</td>
                  <td className="he">{eachAstrologer.phone_number}</td>
                  <td className="he">Accepted</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h1>No Pending Astrologers</h1>
        )}
      </div>
    );
  };

  const getRejectedAstrologerDetails = () => {
    return (
      <div className="register-astro-table-container">
        {rejectedStatusAstrologers.length > 0 ? (
          <table className="register-astrologer-table">
            <thead>
              <tr className="register-rows">
                <th className="he">Name</th>
                <th className="he">Phone Number</th>
                <th className="he">Approval</th>
              </tr>
            </thead>
            <tbody>
              {rejectedStatusAstrologers.map((eachAstrologer) => (
                <tr
                  className="register-details-rows"
                  onClick={() => onClickedAstrologerId(eachAstrologer._id)}
                  key={eachAstrologer._id}
                >
                  <td className="he">{eachAstrologer.astrologerName}</td>
                  <td className="he">{eachAstrologer.phone_number}</td>
                  <td className="he">Rejected</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <h1>No Rejected Astrologers</h1>
        )}
      </div>
    );
  };

  const onChangeStatusSection = (statusValue) => {
    setStatusSection(statusValue);
  };

  const getTheSelectedSectionViewResponse = () => {
    switch (statusSection) {
      case "pending":
        return getPendingStatusAstrologer();
      case "accepted":
        return getApprovedAstrologerDetails();
      case "rejected":
        return getRejectedAstrologerDetails();
      default:
        return null;
    }
  };

  // const getPagePerData = (pageNumber) => {
  //   const slicedData = pendingStatusAstrologers.slice((pageNumber * 10) - 1, pageNumber * 10)
  //   setPerPageData(slicedData)
  // }

  return (
    <div className="astrologer-register-container">
      <div className="Status-panel-container">
        <button
          className={`btn ${
            statusSection === "pending" ? "activeTabSection" : ""
          }`}
          onClick={() => onChangeStatusSection("pending")}
        >
          Pending
        </button>
        <button
          className={`btn ${
            statusSection === "accepted" ? "activeTabSection" : ""
          }`}
          onClick={() => onChangeStatusSection("accepted")}
        >
          Accepted
        </button>
        <button
          className={`btn ${
            statusSection === "rejected" ? "activeTabSection" : ""
          }`}
          onClick={() => onChangeStatusSection("rejected")}
        >
          Rejected
        </button>
      </div>

      {getTheSelectedSectionViewResponse()}
    </div>
  );
};

export default AstrologersRegister;