import React, { useEffect, useState } from "react";
import BASE_URL from "./config";
import Modal from "react-modal";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "./PreferedTime.css";
import Loader from "./Loader";

const PreferredTimeTable = ({ astrologerId }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDay, setSelectedDay] = useState("");
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [modalMessage, setModalMessage] = useState("");

    const fetchPreferredTimes = async () => {
        if (!astrologerId) {
            console.error("Astrologer ID is missing");
            setError("Astrologer ID is required.");
            return;
        }

        try {
            setLoading(true);
            setError("");
            const response = await fetch(
                `${BASE_URL}/astrologer/get-preferred-time/${astrologerId}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            if (result.success) {
                setData(result.days || []);
            } else {
                setError(result.message || "Failed to fetch preferred times.");
            }
        } catch (error) {
            console.error("Error fetching preferred times:", error);
            setError("An error occurred while fetching preferred times.");
        } finally {
            setLoading(false);
        }
    };

    const deleteTheTimings = async (day, timeSlot) => {
        setLoading(true);
        try {
            const response = await fetch(
                `${BASE_URL}/astrologer/preferred-time`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        astrologerId,
                        day,
                        preferredTime: {
                            startTime: timeSlot.startTime,
                            endTime: timeSlot.endTime,
                        },
                        action: "delete",
                    }),
                }
            );

            const result = await response.json();
            if (response.ok && result.success) {
                console.log("Time slot removed successfully");
                fetchPreferredTimes();
                setLoading(false);
            } else {
                console.error("Failed to delete time slot:", result.message);
                alert(result.message || "Failed to delete the time slot.");
            }
        } catch (error) {
            console.error("Error deleting time slot:", error);
            alert("An error occurred while deleting the time slot.");
        } finally {
            setLoading(false);
        }
    };

    const addPreferredTime = async () => {
        if (!startTime || !endTime || !selectedDay) {
            alert("Please select both start time and end time.");
            return;
        }

        const formattedStartTime = dayjs(startTime).format("hh:mm A");
        const formattedEndTime = dayjs(endTime).format("hh:mm A");

        try {
            const response = await fetch(
                `${BASE_URL}/astrologer/preferred-time`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        astrologerId,
                        day: selectedDay,
                        preferredTime: {
                            startTime: formattedStartTime,
                            endTime: formattedEndTime,
                        },
                        action: "add",
                    }),
                }
            );

            const result = await response.json();
            if (response.ok && result.success) {
                console.log("Time slot added successfully");
                fetchPreferredTimes();
                setIsModalOpen(false);
                setModalMessage("");
            } else {
                console.error("Failed to add time slot:", result.message);
                setModalMessage(
                    result.message || "Failed to add the time slot."
                );
            }
        } catch (error) {
            console.error("Error adding time slot:", error);
            setModalMessage("An error occurred while adding the time slot.");
        }
    };

    useEffect(() => {
        fetchPreferredTimes();
    }, [astrologerId]);

    if (loading) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="anvesh-astro-table-container anvesh-pymnts-container">
            <table className="anvesh-astro-table">
                <thead className="anvesh-astro-header">
                    <tr>
                        <th className="anvesh-day-cell">Day</th>
                        <th className="anvesh-actions-cell">Add</th>
                        <th className="anvesh-time-slots-cell">
                            Preferred Time Slots
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((dayData) => (
                        <tr className="anvesh-astro-row" key={dayData.day}>
                            <td className="anvesh-day-cell">{dayData.day}</td>
                            <td className="anvesh-actions-cell">
                                <button
                                    className="anvesh-astro-add-btn"
                                    onClick={() => {
                                        setSelectedDay(dayData.day);
                                        setIsModalOpen(true);
                                        setModalMessage("");
                                    }}
                                >
                                    Add
                                </button>
                            </td>
                            <td className="anvesh-time-slots-cell">
                                {dayData.preferredTime.length > 0 ? (
                                    dayData.preferredTime.map(
                                        (timeSlot, index) => (
                                            <div
                                                className="anvesh-prrrrr-prrr"
                                                key={timeSlot._id || index}
                                            >
                                                <div>
                                                    {timeSlot.startTime} -{" "}
                                                    {timeSlot.endTime}
                                                </div>
                                                <div>
                                                    <button
                                                        className="anvesh-delete-btnbtn"
                                                        onClick={() =>
                                                            deleteTheTimings(
                                                                dayData.day,
                                                                timeSlot
                                                            )
                                                        }
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    )
                                ) : (
                                    <div className="anvesh-no-preferred-times">
                                        No preferred times
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="Add Preferred Time"
                className="anvesh-time-picker-modal"
                overlayClassName="anvesh-time-picker-overlay"
            >
                <h2>Add Preferred Time for {selectedDay}</h2>
                {modalMessage && (
                    <p className="anvesh-error-message">{modalMessage}</p>
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="anvesh-time-picker-container">
                        <TimePicker
                            label="Start Time"
                            value={startTime}
                            minutesStep={15}
                            onChange={(newValue) => setStartTime(newValue)}
                            renderInput={(params) => <input {...params} />}
                        />
                        <TimePicker
                            label="End Time"
                            value={endTime}
                            minutesStep={15}
                            onChange={(newValue) => setEndTime(newValue)}
                            renderInput={(params) => <input {...params} />}
                        />
                    </div>
                </LocalizationProvider>
                <div className="anvesh-modal-actions">
                    <button onClick={addPreferredTime}>Add Time</button>
                    <button onClick={() => setIsModalOpen(false)}>
                        Cancel
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default PreferredTimeTable;
