import { useEffect, useState } from "react";
import WalletPopUp from "../WalletPopUp";
import RefundSuccessPopup from "../RefundSuccessPopup";
import { refundAmount } from "../api/walletApi";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../config";
import { SiGooglemessages } from "react-icons/si";

import "./AstrologerChatTransactions.css";
const AstrologerChatTransactions = ({ astrologerId }) => {
    const [currentAllTransactionsPage, setAllTransactionsCurrentPage] =
        useState(1);
    const [totalAllTransactionsPages, setAllTotalTransactionsPages] =
        useState(null);
    const [allTransactionsData, setAllTransactionsData] = useState([]);
    const [totalNumberOfTransactions, setTotalNumberOfTransactions] =
        useState(0);
    const [callBackData, setCallBackData] = useState({});
    const [popupMessage, setPopupMessage] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const [refundSuccessful, setRefundSuccessful] = useState(false);
    const [successPercentage, setSuccessPercentage] = useState();
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState("");

    const [totalFreeChats, setTotalFreeChats] = useState(0);
    const [totalPaidChats , setTotalPaidChats] = useState(0);
    const [totalChats , setTotalChats ] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);        


    useEffect(() => {
        const controller = new AbortController();

        const dateFunction = async () => {
            const signal = controller?.signal;

            await getWalletAllTransactions(signal);

            return () => {
                controller.abort();
            };
        };

        dateFunction();
    }, [currentAllTransactionsPage, selectedDate]);

    // selectedDate
    const getWalletAllTransactions = async (signal) => {
        try {
            let url = null;
            //url = `${BASE_URL}/wallet/astrologer-chat-transactions?page=${currentAllTransactionsPage}`;

            if (selectedDate) {
                url = `${BASE_URL}/wallet/astrologer-chat-transactions-by-date?date=${selectedDate}&page=${currentAllTransactionsPage}`;
            } else {
                url = `${BASE_URL}/wallet/astrologer-chat-transactions?page=${currentAllTransactionsPage}`;
            }
            // console.log(selectedDate);
            // console.log(url);
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ astrologerId }),
                signal,
            });
            const data = await response.json();

            const { success } = data;
            //console.log(data);
            if (success) {
                const { transactions, pagination , totalChatsDetails  } = data;
                const {totalChats, totalFreeChats, totalPaidChats, totalAmount} = totalChatsDetails
                setTotalFreeChats(totalFreeChats || 0);
                setTotalPaidChats(totalPaidChats || 0);
                setTotalChats(totalChats || 0);
                setTotalAmount(totalAmount || 0);
                if (pagination) {
                    const { totalPages, totalTransactions } = pagination;
                    setTotalNumberOfTransactions(totalTransactions);
                    setAllTotalTransactionsPages(totalPages);
                }
                setAllTransactionsData(transactions);
            } else {
                setAllTransactionsData([]);
            }
        } catch (error) {
            console.error("Error fetching transactions:", error);
        }
    };

    const handleToChangeEdit = (data) => {
        setCallBackData(data);
        setPopupMessage(`Do you want to Refund this Amount?`);
        setPopupVisible(true);
    };

    const closePopup = () => {
        setPopupVisible(false);
        setPopupMessage("");
    };

    const handleRefundSuccess = async (percentage) => {
        try {
            const result = await refundAmount(
                callBackData.id,
                callBackData.userId,
                callBackData.astrologerId,
                callBackData.amount,
                percentage
            );
            setRefundSuccessful(true);
            setSuccessPercentage(percentage);
        } catch (error) {
            console.error("An error occurred: " + error.message);
        }
        closePopup();
    };

    const handleSuccessMessageDismiss = () => {
        const controller = new AbortController();
        const signal = controller?.signal;

        setRefundSuccessful(false);
        getWalletAllTransactions(signal);
    };

    const handlePageChange = (direction) => {
        if (direction === "prev" && currentAllTransactionsPage > 1) {
            setAllTransactionsCurrentPage(currentAllTransactionsPage - 1);
        } else if (
            direction === "next" &&
            currentAllTransactionsPage < totalAllTransactionsPages
        ) {
            setAllTransactionsCurrentPage(currentAllTransactionsPage + 1);
        }
    };
    const handleViewChat = (chat) => {
        console.log(chat);
        const { userId, astrologerId, astrologerName, userName, updatedAt } =
            chat;
        const user = { userName };
        const astrologer = { astrologerName };
        navigate(`/dashboard/getRoomChat/${`${userId}_${astrologerId}`}`, {
            state: { user, astrologer, updatedAt },
        });
    };

    const onChangeDate = async (date) => {
        
         setSelectedDate(date);
         setAllTransactionsCurrentPage(1)
    };
    const WalletAllTransactionsTable = () => (
        <div>

            <div className="chats-count-container">
                <h4>{`Total Chats ${totalChats }`}</h4>
                <h4>{`Total Free Chats ${totalFreeChats}`}</h4> 
                <h4>{`Total Paid Chats ${totalPaidChats}`}</h4> 
                <h4>{`Total Chats Amount Rs=  ${totalAmount}/-`}</h4> 
                 
            </div>
            <div className="astro-table-container pymnts-container">
                <table className="astro-table">
                    <thead className="astro-he">
                        <tr className="astro-bg">
                            <th className="he">Username</th>
                            <th className="he">User Number</th>
                            <th className="he">Astrologer Name</th>
                            <th className="he">Astrologer Number</th>
                            <th className="he">
                                Pre-consultation Wallet Balance
                            </th>
                            <th className="he">
                                Post-consultation Wallet Balance
                            </th>
                            <th className="he">Type</th>
                            <th className="he">Date-Time</th>
                            <th className="he">Session Amount</th>
                            <th className="he">Session Time (MIN)</th>
                            <th className="he">View Chat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allTransactionsData?.map((transaction) => (
                            <tr className="astro-rows" key={transaction.id}>
                                <td
                                    className="he"
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/user/${transaction.userId}`,
                                            {
                                                state: {
                                                    user: {
                                                        _id: transaction?.userId,
                                                        userName:
                                                            transaction?.userName,
                                                        phone_number:
                                                            transaction?.userNumber,
                                                    },
                                                },
                                            }
                                        )
                                    }
                                    style={{ color: "blue", cursor: "pointer" }}
                                >
                                    {transaction?.userName || "Loading..."}
                                </td>
                                <td className="he">{transaction.userNumber}</td>
                                <td className="he">
                                    {transaction.astrologerName}
                                </td>
                                <td className="he">
                                    {transaction.astrologerNumber}
                                </td>
                                <td className="he">
                                    {transaction.beforeAstrologerWalletBalance}
                                </td>
                                <td className="he">
                                    {transaction.afterAstrologerWalletBalance}
                                </td>
                                <td className="he">{transaction.type}</td>
                                <td className="he wallet-date-time">
                                    {new Date(
                                        transaction.date
                                    ).toLocaleString("en-GB", {
                                       
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",
                                        hour12: true,
                                    })}
                                </td>
                                <td className="he">{transaction.amount}</td>
                                <td className="he">
                                    {transaction.session_time}
                                </td>
                                <td>
                                    <SiGooglemessages
                                        className="messeges-google-icon"
                                        onClick={() =>
                                            handleViewChat(transaction)
                                        }
                                        size={35}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <button
                    className="pagination-button"
                    onClick={() => handlePageChange("prev")}
                    disabled={currentAllTransactionsPage === 1}
                >
                    Prev
                </button>
                <span className="pagination-info">
                    Page {currentAllTransactionsPage} of{" "}
                    {totalAllTransactionsPages}
                </span>
                <button
                    className="pagination-button"
                    onClick={() => handlePageChange("next")}
                    disabled={
                        currentAllTransactionsPage === totalAllTransactionsPages
                    }
                >
                    Next
                </button>
            </div>
            {popupVisible && (
                <WalletPopUp
                    message={popupMessage}
                    onCancel={closePopup}
                    onRefundSuccess={handleRefundSuccess}
                    callBackData={callBackData}
                />
            )}
            {refundSuccessful && (
                <RefundSuccessPopup
                    successPercentage={successPercentage}
                    onClose={handleSuccessMessageDismiss}
                />
            )}
        </div>
    );

    return (
        <div>
            <h1 className="astrologer-wallet-chat-date-heading-name">
                Select Chat Date
            </h1>
            <div className="astrologer-wallet-chat-date-container">
                <input
                    type="date"
                    value={selectedDate}
                    onChange={(event) => onChangeDate(event.target.value)}
                    className="astrologer-wallet-chat-date-input"
                />
                <button
                    className="astrologer-wallet-chat-date-clear-button"
                    onClick={() => setSelectedDate("")}
                >
                    Clear
                </button>
            </div>
            {selectedDate && (
                    <p className="selected-date-text">{`Selected date: ${selectedDate}`}</p>
                )}
            {allTransactionsData.length > 0 ? (
                <div>
                     
                    {WalletAllTransactionsTable()}</div>
            ) : (
                "No transaction history found!"
            )}
        </div>
    );
};

export default AstrologerChatTransactions;
