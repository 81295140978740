import { useEffect, useState } from "react";
import { refundAmount } from "../api/walletApi";
import WalletPopUp from "../WalletPopUp";
import RefundSuccessPopup from "../RefundSuccessPopup";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { SiGooglemessages } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../config";
const UserWalletTransactions = ({ user }) => {
    const [currentTransactionsPage, setCurrentTransactionPage] = useState(1);
    const [totalTransactionsPages, setTotalTransactionsPages] = useState(1);
    const [transactionData, setTransactionData] = useState([]);
    const [refundSuccessful, setRefundSuccessful] = useState(false);
    const [popupMessage, setPopupMessage] = useState("");
    const [popupVisible, setPopupVisible] = useState(false);
    const [successPercentage, setSuccessPercentage] = useState();
    const [totalTransactions, setTotalTransactions] = useState(0);
    const [callBackData, setCallBackData] = useState({});
    const [abortController, setAbortController] = useState(null);
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState("");

    const [totalFreeChats, setTotalFreeChats] = useState(0);
    const [totalPaidChats , setTotalPaidChats] = useState(0);
    const [totalChats , setTotalChats ] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0); 

    const getSpecificUserWalletTrans = async () => {
        try {
            console.log("page no", currentTransactionsPage);

            const controller = new AbortController();
            setAbortController(controller);

            let getTransactionsApiUrl = null;

            if (selectedDate) {
                 
                getTransactionsApiUrl = `${BASE_URL}/wallet/user-wallet-transactions-by-date?date=${selectedDate}&page=${currentTransactionsPage}`;
            } else {
                getTransactionsApiUrl = `${BASE_URL}/wallet/user-wallet-transactions?page=${currentTransactionsPage}`;
            }

            console.log(getTransactionsApiUrl);
            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ userId: user }),
                signal: controller?.signal,
            };
            const response = await fetch(getTransactionsApiUrl, options);
            const data = await response.json();
            const { success } = data;
            console.log("user wallet data", data);
            if (data.success === true) {
                console.log("if success", data.success);
                const { transactions, pagination, totalChatDetails} = data;
                const { totalPages, totalTransactions } = pagination;
                const  { totalChats, totalFreeChats, totalPaidChats, totalAmount } = totalChatDetails ;
                setTransactionData(transactions);
                setTotalTransactionsPages(totalPages);
                setTotalTransactions(totalTransactions);


                setTotalFreeChats(totalFreeChats || 0);
                setTotalPaidChats(totalPaidChats || 0);
                setTotalChats(totalChats || 0);
                setTotalAmount(totalAmount || 0);
                //console.log("user ka transactions", transactionData);
            } else {
                setTransactionData([]);
                setTotalTransactionsPages(1);
                setTotalTransactions(0);
            }
        } catch (error) {
            console.error("Error fetching user transactions:", error);
        }
    };

    const closePopup = () => {
        setPopupVisible(false);
        setPopupMessage("");
    };

    const handlePageChange = (direction) => {
        if (direction === "prev" && currentTransactionsPage > 1) {
            setCurrentTransactionPage(currentTransactionsPage - 1);
        } else if (
            direction === "next" &&
            currentTransactionsPage < totalTransactionsPages
        ) {
            setCurrentTransactionPage(currentTransactionsPage + 1);
        }
    };

    const handleRefundSuccess = async (percentage) => {
        try {
            const result = await refundAmount(
                callBackData.id,
                callBackData.userId,
                callBackData.astrologerId,
                callBackData.amount,
                percentage
            );
            setRefundSuccessful(true);
            setSuccessPercentage(percentage);
        } catch (error) {
            console.error("An error occurred: " + error.message);
        }
        closePopup();
    };

    const handleSuccessMessageDismiss = () => {
        setRefundSuccessful(false);
        getSpecificUserWalletTrans();
    };

    const handleToChangeEdit = (data) => {
        setCallBackData(data);
        setPopupMessage(`Do you want to Refund this Amount?`);
        setPopupVisible(true);
    };

    const handleViewChat = (chat) => {
        console.log(chat);
        const { userId, astrologerId, astrologerName, updatedAt } = chat;
        const user = userId;
        const astrologer = { astrologerName };
        navigate(`/dashboard/getRoomChat/${`${userId._id}_${astrologerId}`}`, {
            state: { user, astrologer, updatedAt },
        });
    };

    const getUserWalletTable = () => (
        < div>
        <div className="chats-count-container">
                <h4>{`Total Chats ${totalChats }`}</h4>
                <h4>{`Total Free Chats ${totalFreeChats}`}</h4> 
                <h4>{`Total Paid Chats ${totalPaidChats}`}</h4> 
                <h4>{`Total Chats Amount Rs=  ${totalAmount}/-`}</h4> 
                 
            </div>
            <div className="astro-table-container pymnts-container">
                <table className="astro-table">
                    <thead className="astro-he">
                        <tr className="astro-bg">
                            <th className="he">Astrologer Name</th>
                            <th className="he">
                                Pre-consultation Wallet Balance
                            </th>
                            <th className="he">
                                Post-consultation Wallet Balance
                            </th>
                            <th className="he">Type</th>
                            <th className="he">Date-Time</th>
                            <th className="he">Session Amount</th>
                            <th className="he">Session Time(MIN)</th>
                            <th className="he">Refund</th>
                            <th className="he">View Chat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactionData.map((callBackData) => (
                            <tr className="astro-rows" key={callBackData.id}>
                                <td
                                    className="he"
                                    onClick={() => {
                                        navigate(
                                            `/dashboard/astrologer/${callBackData.astrologerId}`,
                                            {
                                                state: {
                                                    astrologer:
                                                        callBackData.astrologer,
                                                },
                                            }
                                        );
                                    }}
                                    style={{ color: "blue", cursor: "pointer" }}
                                >
                                    {callBackData?.astrologerName ||
                                        "Loading..."}
                                </td>
                                <td className="he">
                                    {callBackData.beforeUserWalletBalance}
                                </td>
                                <td className="he">
                                    {callBackData.afterUserWalletBalance}
                                </td>
                                <td className="he">{callBackData.type}</td>
                                <td className="he wallet-date-time">
                                    {new Date(
                                        callBackData.date
                                    ).toLocaleString("en-GB", {
                                       
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        second: "2-digit",
                                        hour12: true,
                                    })}
                                </td>
                                <td className="he">{callBackData.amount}</td>
                                <td className="he">
                                    {callBackData.session_time}
                                </td>
                                <td className="he">
                                    {callBackData.refund === false ? (
                                        new Date() -
                                            new Date(callBackData.date) <=
                                        48 * 60 * 60 * 1000 ? (
                                            <button
                                                className="edit-button"
                                                onClick={() =>
                                                    handleToChangeEdit(
                                                        callBackData
                                                    )
                                                }
                                            >
                                                Edit
                                            </button>
                                        ) : (
                                            <p className="not-available-paragraph">
                                                Not available for refund
                                            </p>
                                        )
                                    ) : (
                                        <p className="refund-paragraph">
                                            {callBackData.refundPercentage ||
                                                "0"}
                                            % Refund <IoCheckmarkDoneCircle />
                                        </p>
                                    )}
                                </td>

                                <td>
                                    <SiGooglemessages
                                        className="messeges-google-icon"
                                        onClick={() =>
                                            handleViewChat(callBackData)
                                        }
                                        size={35}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <button
                    className="pagination-button"
                    onClick={() => handlePageChange("prev")}
                    disabled={currentTransactionsPage === 1}
                >
                    Prev
                </button>
                <span className="pagination-info">
                    Page {currentTransactionsPage} of {totalTransactionsPages}
                </span>
                <button
                    className="pagination-button"
                    onClick={() => handlePageChange("next")}
                    disabled={
                        currentTransactionsPage === totalTransactionsPages
                    }
                >
                    Next
                </button>
            </div>
            {popupVisible && (
                <WalletPopUp
                    message={popupMessage}
                    onCancel={closePopup}
                    onRefundSuccess={handleRefundSuccess}
                    callBackData={callBackData}
                />
            )}
            {refundSuccessful && (
                <RefundSuccessPopup
                    successPercentage={successPercentage}
                    onClose={handleSuccessMessageDismiss}
                />
            )}
        </div>
    );

    const onChangeDate = async (date)=>{
             setSelectedDate(date);  
             setCurrentTransactionPage(1);
    }

    useEffect(() => {
        const dateFunction = async () => {
            await getSpecificUserWalletTrans();
            return () => {
                if (abortController) {
                    abortController.abort();
                }
            };
        };

        dateFunction();
    }, [currentTransactionsPage, selectedDate]);

    return (
        <div>
            <h1 className="astrologer-wallet-chat-date-heading-name">
                Select Chat Date
            </h1>
            <div className="astrologer-wallet-chat-date-container">
                <input
                    type="date"
                    value={selectedDate}
                    onChange={ event => onChangeDate(event.target.value) }
                    className="astrologer-wallet-chat-date-input"
                />
                <button
                    className="astrologer-wallet-chat-date-clear-button"
                    onClick={() => setSelectedDate("")}
                >
                    Clear
                </button>
            </div>
            {selectedDate && (
                    <p className="selected-date-text">{`Selected date: ${selectedDate}`}</p>
                )}
            {transactionData.length > 0 ? (
                getUserWalletTable()
            ) : (
                <h1>There is No Chat Transactions are there!</h1>
            )}
        </div>
    );
};

export default UserWalletTransactions;
