import { useState } from "react";
import AstrologerChatTransactions from "./AstrologerChatTransactions";
import AstrologerCallTransactions from "./AstrologerCallTransactions";
const AstrologerWalletMain = ({ astrologerId }) => {
    const [filter, setFilter] = useState("chat");
    return (
        <div>
            <div className="filters">
                <button
                    className={`filter-button ${
                        filter === "chat" ? "active-filter" : ""
                    }`}
                    onClick={() => setFilter("chat")}
                >
                    Chat
                </button>
                <button
                    className={`filter-button ${
                        filter === "call" ? "active-filter" : ""
                    }`}
                    onClick={() => setFilter("call")}
                >
                    Call
                </button>
            </div>
            {filter === "chat" && (
                
                <AstrologerChatTransactions astrologerId={astrologerId} />
            )}
            {filter === "call" && (
                <AstrologerCallTransactions astrologerId={astrologerId} />
            )}
        </div>
    );
};

export default AstrologerWalletMain;
